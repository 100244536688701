import { FTCanvasStore, FTUserStore } from "src/store";
import FTEventTarget, { FTEVENT_CONSTANTS } from "./FTEventTarget";

import { PROJECT_TYPE } from "src/constant";

export const AD_ID = {
  // 顶部广告
  TOP: "goart_top",
  // 工具导出广告
  EXPORT: "goart_export",
  // 驻留广告
  RESIDENT: "goart_resident"
};

export function loadAdsJs(callback) {
  if (window && window.adpopup) {
    callback && callback();
    return;
  }
  loadScript(process.env.REACT_APP_AD, () => {
    callback && callback();
  });
}

export function loadScript(src, callback) {
  let script = document.createElement("script");
  script.setAttribute("async", "async");
  script.setAttribute("src", src);
  script.onload = () => callback(null, script);
  // script.onerror = () => callback(new Error(`Script load error for ${src}`));
  document.body.appendChild(script);
}

export const loadScriptPromise = (src: string, options?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    if (options) {
      Object.keys(options).forEach((key) => {
        script.setAttribute(key, options[key]);
      });
    }
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

export const initAD = (adInfo?, callback?) => {
  // 使用react-snap预渲染时，会出错，且没有必要渲染
  if (navigator.userAgent !== "ReactSnap") {
    const adList = [{ eleId: AD_ID.RESIDENT, type: "resident" }];
    if (adInfo) {
      if (Array.isArray(adInfo)) {
        adList.push(...adInfo);
      } else {
        adList.push(adInfo);
      }
    }

    loadAdsJs(() => {
      window.adpopup?.fillAds(
        adList,
        (res) => {
          callback?.(res);
          adCallback(res);
        },
        { toolType: "goartTool" }
      );
    });
  }
};

export const adCallback = (res: any) => {
  if (res.type === "confirm" && res.action === false) {
    if (
      res.adData.jumpUrl === "upgrade" ||
      res.adData.jumpUrl === "upgradesvip"
    ) {
      const senceId = res.adData?.sceneId || "012";
      const params = {};
      if (senceId) {
        params["scenesId"] = senceId;
      }
      const promotionId = res.adData?.promotionId;
      if (promotionId && promotionId !== null && promotionId.length > 0) {
        params["promotionId"] = promotionId[0];
      }
      const adPostion = "ad_" + res.adData.id;
      const activeBlackFriday =
        FTUserStore.activeBlackFriday;
      if (FTUserStore.userInfo) {
        FTUserStore.showPaymentBase({
          vipType: activeBlackFriday ? 1 : res.adData.jumpUrl === "upgrade" ? 0 : 1,
          position: adPostion,
          otherParam: params,
          callback: () => {
            // FTBgremoveStore.updateBgRemoveChannel();
            // FTBgremoveStore.updateCredits();
            // FTBgremoveStore.updateUsageRatio();
          },
          payType: res.adData.displayLocation,
          projectType: PROJECT_TYPE
        });
      } else {
        FTUserStore.showLogin({
          isSignIn: false,
          options: {
            position: adPostion
          },
          isShowScenses: true,
          otherParam: {
            scenesId: "014"
          },
          callback: () => {
            FTUserStore.showPaymentBase({
              vipType: activeBlackFriday ? 1 : res.adData.jumpUrl === "upgrade" ? 0 : 1,
              position: adPostion,
              otherParam: params,
              callback: () => {
                // FTBgremoveStore.updateBgRemoveChannel();
                // FTBgremoveStore.updateCredits();
                // FTBgremoveStore.updateUsageRatio();
              },
              payType: res.adData.displayLocation,
              projectType: PROJECT_TYPE
            });
          }
        });
      }
    } else {
      window.open(res.adData.jumpUrl, "_blank");
    }
  } else if (res.type === "login") {
    // 广告里面的登录回调, 更新登录信息
    FTUserStore.loginCallback(res.data);
  } else if (res.type === "payment") {
    // 广告里面的支付回调, 更新支付信息
    FTUserStore.paymentCallback(res.data);
  }
  if (res.type === "show" && res.adData.type === "top") {
    const dom: any = document.querySelector("#fotorAdWapper");
    const goart_topDom: any = document.querySelector("#goart_top");
    if (dom) {
      if (goart_topDom) {
        dom.style.height = `calc(100% - ${goart_topDom.clientHeight}px) - 70px`;
        dom.style.top = `${goart_topDom.clientHeight}px`;
      }
    }
    FTEventTarget.getInstance().fireEvent({
      type: FTEVENT_CONSTANTS.CHANGE_AD_SHOW
    });
  }
  if (res.type === "close" && res.adData.type === "top") {
    const dom: any = document.querySelector("#fotorAdWapper");
    if (dom) {
      dom.style.height = `calc(100% - 70px)`;
      dom.style.top = `0px`;
    }
    FTEventTarget.getInstance().fireEvent({
      type: FTEVENT_CONSTANTS.CHANGE_AD_SHOW
    });
  }
};

// export const updateAD = () => {
//   return autorun(() => {
//     initAD({
//       isMobile: FTCanvasStore.isMobile,
//       userInfo: FTUserStore.userInfo,
//       isVip: FTUserStore.isVip,
//       showLoading: FTBgremoveStore.showLoading,
//     });
//   });
// };
