import { Controls, Player } from "@lottiefiles/react-lottie-player";

import JSon from "../FTLoading/fotor-web-loading.json";
import clsx from "clsx";
import styles from "./index.module.scss";
import { useRef } from "react";

type FtLoadingProps = {
  className?: string;
};

const FotorLoading = (props: FtLoadingProps) => {
  const { className } = props;

  const loadingEleRef = useRef<HTMLDivElement>(null);

  // const isMobile = useCommonStore((state) => state.isMobile);

  return (
    <div className={clsx(styles["root"], className)}>
      <div ref={loadingEleRef} className={styles["loading"]}>
        <Player autoplay loop src={JSon} style={{ width: "120px" }}>
          <Controls visible={false} />
        </Player>
      </div>
    </div>
  );
};

export default FotorLoading;
