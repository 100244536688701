interface LocalStorageItem {
  value: any;
  expiry: number; // 过期时间，以毫秒为单位的时间戳
}

export class LocalStorageManager {
  static ONEDAY = 24 * 60 * 60 * 1000;
  static ONEMONTH = 30 * 24 * 60 * 60 * 1000;
  static ONEYEAR = 365 * 24 * 60 * 60 * 1000;
  // 设置带过期时间的键值对
  static setItem<T = any>(key: string, value: T, ttl: number) {
    const now = new Date();
    const item: LocalStorageItem = {
      value: value,
      expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  // 获取键值对，如果过期则返回 null 并删除该键值对
  static getItem(key: string): any | null {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item: LocalStorageItem = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  // 删除指定的键值对
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  // 清空所有键值对
  static clear() {
    localStorage.clear();
  }
}

export const SHOW_VIDEO_LINK_POPOVER_KEY = "SHOW_VIDEO_LINK_POPOVER";
export const GOART_TASK_LIST_KEY = "GOART_TASK_LIST";
