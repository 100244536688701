/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-30 10:08:21
 * @Description:
 */
import { FTGetImageUrl, FTUserProfile } from "src/server/api";
import { deeplink, urls } from "src/constant";

import { LANGUAGE_TYPE } from "./utils";
import clsx from "clsx";
import { default as defaultAxios } from "axios";
import { errorCode } from "src/constant";
import utils from "./utils";
import utilsStyle from "./utils.module.scss";

export { default as FTFotorFeatureDB } from "./FTFotorFeatureDB";
export { default as FTLang } from "./FTLang";
export { default as PostMessage } from "./PostMessage";
export { default as FreeTryPanel } from "./FTVipTip";
export { default as FTADManager } from "./FTADManager";
export { default as CutOutAvatar } from "./checkAvatar";
export { utils, LANGUAGE_TYPE };
export * from "./canvas";
export * from "./object";
export * from "./ads";

export function cloneCanvas(oldCanvas: HTMLCanvasElement) {
  //create a new canvas
  let newCanvas = document.createElement("canvas");
  let context = newCanvas.getContext("2d")!;

  //set dimensions
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;

  //apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0);

  //return the new canvas
  return newCanvas;
}

//打开或下载app
export function openOrDownloadApp(
  appStoreUrl: string,
  client: any = utils.myBrowserOS(),
  schema = "fotor://"
) {
  let { android, ios } = client;

  const gotoDownload = () => {
    window.location.href = decodeURIComponent(appStoreUrl);
  };
  if (ios) {
    // ios 直接去下载地址,不用打开app
    gotoDownload();
    // //ios使用通用链接调起 app
    // window.location.href =
    //   iosUniversalLinks +
    //   "?furl=" +
    //   encodeURIComponent(schema) +
    //   "&downloadNow=" +
    //   encodeURIComponent(appStoreUrl);
  } else if (android) {
    //调起app
    // schema &&  window.location.assign(schema);
    //转到下载页
    gotoDownload();
    //转到下载页
    // //3秒后 执行 [转到下载] 任务
    // let downloadTimer = setTimeout(() => {
    //   gotoDownload();
    // }, 3000);
    // //如果app被成功调起，visibilitychange 事件会被触发。
    // document.addEventListener(
    //   "visibilitychange",
    //   function run() {
    //     console.log("触发 visibilitychange", new Date().toString());
    //     document.removeEventListener("visibilitychange", run, false);
    //     clearTimeout(downloadTimer); //清除 [转到下载] 任务
    //   },
    //   false
    // );
  } else {
    gotoDownload();
  }
}

function getDeepLink(linkKey: string, client: any = utils.myBrowserOS()) {
  // console.log("打开deeplink", linkKey, new Date().toString());
  const storeLink = deeplink[client.ios ? "ios" : "android"];
  return storeLink[linkKey];
}

function getNormalLink(linkKey: string, client: any = utils.myBrowserOS()) {

  const linkKeyMap = {
    ios: {
      homepage_nav_button:  'https%3A%2F%2Fapps.apple.com%2Fapp%2Ffotor-ai-photo-editor%2Fid440159265%3Fppid%3Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d',
      dialog_open_app: 'https%3A%2F%2Fapps.apple.com%2Fapp%2Ffotor-ai-photo-editor%2Fid440159265%3Fppid%3Dcde6b4ea-5932-48ae-8518-aa1bfb1bf44d'
    },
    android: {
      homepage_nav_button:'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.everimaging.photoeffectstudio%26listing%3Dai-cartoon',
      dialog_open_app: 'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.everimaging.photoeffectstudio%26listing%3Dai-cartoon'
    }
  }

  const storeLink = linkKeyMap[client.ios ? "ios" : "android"];
  return storeLink[linkKey];
}

//打开app 或 通过deeplink下载app
export function openAppOrToDeeplink(
  linkKey: string,
  client: any = utils.myBrowserOS(),
  schema = "goart://"
) {
  // adjust
  // openOrDownloadApp(getDeepLink(linkKey, client), client, schema);
  // 应用商店
  openOrDownloadApp(getNormalLink(linkKey, client), client, schema);
}

// 设置localStorage
export const FTSaveLocalData = (key: string, value: any) => {
  if (!key) return;
  if (!value) {
    window.localStorage.removeItem(key);
    return;
  }
  if (typeof value !== "string") {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
};
export const FTGetLocalData = (key: string) => {
  if (typeof window.localStorage.getItem(key) !== "undefined") {
    try {
      return JSON.parse(window.localStorage.getItem(key)!);
    } catch (e) {
      return window.localStorage.getItem(key);
    }
  }
  return null;
};

export const getAIGCLink = (
  type: "textToImage" | "imageToImage" | "toAvatar"
) => {
  const typeMap = {
    // textToImage: "creating",
    // imageToImage: "cartoon"
    textToImage: "text",
    imageToImage: "image",
    toAvatar: "avatar"
  };
  if (typeMap[type] === typeMap.toAvatar) {
    return `${urls.baseUrl}avatar/create?utm_source=goart`;
  }
  // return `${urls.baseUrl}features/ai-image-generator/?tooltype=${typeMap[type]}`
  // return `${urls.baseUrl}images/create/?tooltype=${typeMap[type]}`
  return `${urls.baseUrl}images/create?type=${typeMap[type]}&utm_source=goart`;
};

/**
 * 初始人机验证获得token 与后端交互
 * @param actionName 行为名称
 * @param callback 回调
 */
export function recaptchaReady(actionName: string): Promise<string> {
  const windows: any = window;
  return new Promise((resolve, reject) => {
    if (
      windows.grecaptcha &&
      windows.grecaptcha.enterprise &&
      windows.grecaptcha.enterprise.ready
    ) {
      windows.grecaptcha.enterprise.ready(function () {
        windows.grecaptcha.enterprise
          .execute("6LcxshocAAAAAG4Xpw7Mg1CJdycEUyPdXSeF2ToU", {
            action: actionName
          })
          .then(function (token: string) {
            resolve(token);
          })
          .catch((error: any) => {
            reject();
          });
      });
    } else {
      reject();
    }
  });
}

/**
 * 渲染人机验证
 * @param {*} domId domId
 * @param {*} verifyCallback 验证回调
 */
export function renderRecaptcha(
  domId: string,
  verifyCallback: (token: string) => void
) {
  const windows: any = window;
  if (
    windows.grecaptcha &&
    windows.grecaptcha.enterprise &&
    windows.grecaptcha.enterprise.render
  ) {
    const domBox = document.getElementById(domId);

    if (domBox) {
      let id = windows.grecaptcha.enterprise.render(domId, {
        sitekey: "6Lfa4BwcAAAAACLfJjPNBjn-aOkRR-P2EEXL9Sdm",
        callback: verifyCallback
      });
      return id;
    }
  }
}

export const createRecaptcha = () => {
  return new Promise((resolve, reject) => {
    const scriptRecaptcha = document.createElement("script");
    scriptRecaptcha.type = "text/javascript";
    scriptRecaptcha.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=explicit`;
    // scriptRecaptcha.async = true;
    // scriptRecaptcha.defer = true;
    let all = 0;
    scriptRecaptcha.onload = () => {
      all++;
      if (all === 2) {
        resolve(true);
      }
    };
    scriptRecaptcha.onerror = (error) => {
      reject(errorCode.robotFail);
    };
    document.head.append(scriptRecaptcha);
    // if (
    //   window.grecaptcha &&
    //   window.grecaptcha.enterprise &&
    //   window.grecaptcha.ready
    // ) {
    //   return
    // }
    const scriptRecaptcha2 = document.createElement("script");
    scriptRecaptcha2.type = "text/javascript";
    scriptRecaptcha2.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=6LcxshocAAAAAG4Xpw7Mg1CJdycEUyPdXSeF2ToU`;
    // scriptRecaptcha2.async = true;
    // scriptRecaptcha2.defer = true;
    scriptRecaptcha2.onload = () => {
      all++;
      if (all === 2) {
        resolve(true);
      }
    };
    scriptRecaptcha2.onerror = (error) => {
      reject(errorCode.robotFail);
    };
    document.head.append(scriptRecaptcha2);
  });
};

export function getUploadUrl(): Promise<{
  uploadUrl: string;
  key: string;
  backupUploadUrl: string;
}> {
  return new Promise((resolve, reject) => {
    FTGetImageUrl()
      .then((res) => {
        if (res.code === "000") {
          resolve(res.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

interface UploadParam {
  contentType: string;
  url: string;
  file: File;
}
// 上传文件到oss 需要用户 token 验证
function putFileToOss(
  { contentType, url, file }: UploadParam,
  onUploadProgress: any
) {
  let userInfo = FTGetLocalData("fotor_common_user_Info");
  let userToken = userInfo && (userInfo.fotorToken || userInfo.token);
  let headers = {};
  if (!userToken) {
    headers = {
      "Content-Type": contentType
    };
  } else {
    headers = {
      "Content-Type": contentType
      // 'x-oss-meta-author': userToken,
    };
  }
  return defaultAxios({
    method: "PUT",
    url,
    data: file,
    headers: headers,
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      onUploadProgress && onUploadProgress(loaded / total);
    },
    withCredentials: false
  });
}

export function uploadS3File(url: string, image: File, onUploadProgress?: any) {
  return new Promise((resolve, reject) => {
    putFileToOss(
      {
        contentType: "image/jpeg",
        url: url,
        file: image
      },
      onUploadProgress
    )
      .then((res) => {
        if (res?.status === 200) {
          resolve(true);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
}

export function hanldelFTUserProfile() {
  FTUserProfile("c1c4d9514b5c4efe9943e6ac62cef9b4");
}

/**
 * 深拷贝
 * @param target
 * @returns
 */
export function deepClone(target: any) {
  // WeakMap作为记录对象Hash表（用于防止循环引用）
  const map = new WeakMap();

  // 判断是否为object类型的辅助函数，减少重复代码
  function isObject(target: any) {
    return (
      (typeof target === "object" && target) || typeof target === "function"
    );
  }

  function clone(data: any) {
    // 基础类型直接返回值
    if (!isObject(data)) {
      return data;
    }

    // 日期或者正则对象则直接构造一个新的对象返回
    if ([Date, RegExp].includes(data.constructor)) {
      return new data.constructor(data);
    }

    // 处理函数对象
    if (typeof data === "function") {
      return new Function("return " + data.toString())();
    }

    // 如果该对象已存在，则直接返回该对象
    const exist = map.get(data);
    if (exist) {
      return exist;
    }

    // 处理Map对象
    if (data instanceof Map) {
      const result = new Map();
      map.set(data, result);
      data.forEach((val, key) => {
        // 注意：map中的值为object的话也得深拷贝
        if (isObject(val)) {
          result.set(key, clone(val));
        } else {
          result.set(key, val);
        }
      });
      return result;
    }

    // 处理Set对象
    if (data instanceof Set) {
      const result = new Set();
      map.set(data, result);
      data.forEach((val) => {
        // 注意：set中的值为object的话也得深拷贝
        if (isObject(val)) {
          result.add(clone(val));
        } else {
          result.add(val);
        }
      });
      return result;
    }

    // 收集键名（考虑了以Symbol作为key以及不可枚举的属性）
    const keys = Reflect.ownKeys(data);
    // 利用 Object 的 getOwnPropertyDescriptors 方法可以获得对象的所有属性以及对应的属性描述
    const allDesc = Object.getOwnPropertyDescriptors(data);
    // 结合 Object 的 create 方法创建一个新对象，并继承传入原对象的原型链， 这里得到的result是对data的浅拷贝
    const result = Object.create(Object.getPrototypeOf(data), allDesc);

    // 新对象加入到map中，进行记录
    map.set(data, result);

    // Object.create()是浅拷贝，所以要判断并递归执行深拷贝
    keys.forEach((key) => {
      const val = data[key];
      if (isObject(val)) {
        // 属性值为 对象类型 或 函数对象 的话也需要进行深拷贝
        result[key] = clone(val);
      } else {
        result[key] = val;
      }
    });
    return result;
  }

  return clone(target);
}

export function withPublicUrl(...urls: string[]) {
  const publicUrl = process.env.PUBLIC_URL;
  const baseUrl = publicUrl.endsWith("/") ? publicUrl.slice(0, -1) : publicUrl;
  const endUrl = urls.join("/");
  if (endUrl.startsWith("http")) {
    return endUrl;
  }
  return baseUrl + "/" + urls.join("/");
}

export function isNoReactSnap() {
  return typeof window !== "undefined";
}

export function withUnit(value: number, unit: string = "px") {
  return value + unit;
}

export function rangNumber(min: number, max: number, value: number) {
  return Math.max(min, Math.min(max, value));
}

export function sleep(time: number, fn?: (timer: number) => void) {
  return new Promise<void>((resolve) => {
    const timer = setTimeout(() => {
      fn?.(-1);
      resolve();
    }, time);
    fn?.(timer as unknown as number);
  });
}

export function withAssetsUrl(...urls: string[]) {
  const publicUrl = process.env.REACT_APP_ASSETS_URL || "";
  const baseUrl = publicUrl.endsWith("/") ? publicUrl.slice(0, -1) : publicUrl;
  const endUrl = urls.join("/");
  if (endUrl.startsWith("http")) {
    return endUrl;
  }
  return baseUrl + "/" + urls.join("/");
}

/** loading css 样式 */
export const imageLoadingClassName = ((): ((isLight?: boolean) => string) => {
  // 每张图片的都有不同的延迟
  let delay = 0;
  return (isLight?: boolean): string => {
    return ` ${clsx(
      utilsStyle.imageLoading,
      isLight && utilsStyle.isLight,
      utilsStyle[`imageLoadingDelay${delay++ % 10}`]
    )} `;
  };
})();

export const getCurrentTime = (type: "date1") => {
  if (type === "date1") {
    let date = new Date();
    let timestamp =
      date.getFullYear().toString() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0") +
      date.getHours().toString().padStart(2, "0") +
      date.getMinutes().toString().padStart(2, "0") +
      date.getSeconds().toString().padStart(2, "0");
    return timestamp;
  }
};

// 获取AB测试分组 param_name ->  Button  default_value -> A value_type -> String
export const getABTestGroup = ({
  param_name,
  default_value,
  value_type,
  callbackA,
  callbackB
}) => {
  if (!window?.sensors?.ABTest) {
    // 如果神策abtest没有加载，直接返回默认值
    console.log("default", "--------------------------------------------");
    callbackA && callbackA();
    return;
  }
  window?.sensors?.ABTest?.fastFetchABTest?.({
    param_name,
    default_value,
    value_type,
    callback: (res) => {
      // test1 test2 为结果再测试A、B后台配置的
      console.log(res, "--------------------------------------------");
      if (res && (res === "test2" || res === "b")) {
        callbackB && callbackB(res);
      } else {
        callbackA && callbackA(res);
      }
      console.log(res, "--------------------------------------------");
    }
  });
};
