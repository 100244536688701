/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-07 18:18:24
 * @Description:
 */

import "./ToolLeft.scss";
import "./ToolLeftMobile.scss";

import { EnumsShowStatus, FAVOR, urls } from "src/constant";
import EventBusInstances, { EventName } from "src/constant/eventBus";
import { FTImageStore, FTUserStore } from "src/store";
import { FTLang, utils } from "src/utils";
import {
  FolderIcon,
  IconCheck,
  IconClose,
  IconHot,
  IconNew,
  IconSuccess,
  MoreIcon,
  ReGenerateIcon,
  StarFillIcon,
  StarIcon
} from "src/assets";
import { IClassifyItem, IEffectItem } from "src/store/types";
import React, { useEffect, useRef, useState } from "react";
import { actionClickUp, actionGroupClickUp } from "src/analyze/actions";
import { cache_click_red_tag, checkHasCLickRedTag } from "src/utils/untils";
import {
  useChoicenessList,
  useCurrentEffect,
  useCurrentEffectType,
  useCurrentEffectTypeId,
  useEffectClassifyMap,
  useLanguage
} from "src/hooks";

import { FTScrollbar } from "src/components";
import { Tooltip } from "antd";
import { autorun } from "mobx";
import clsx from "clsx";
import styles from "./ToolLeftMobile.module.scss";

interface IProps {
  className: string;
}

const InnerContent = ({
  item,
  currentEffect,
  result,
  setEffectProcess,
  classifyItem,
  setShowPanel
}) => {
  const timeRecords = useRef(0);
  const timer = useRef<NodeJS.Timeout | number>();

  const [showTips, setShowTips] = useState(false);
  //更新动画
  const upadteTimer = useRef<any>(null);
  const [updateAnimation, setUpdateAnimation] = useState(false);

  useEffect(() => {
    function exe() {
      setShowTips(false);
      setUpdateAnimation(false);
    }
    EventBusInstances.addEvents(EventName.CLEAR_TIPS, exe);
    return () => {
      EventBusInstances.removeEvents(EventName.CLEAR_TIPS, exe);
    };
  }, []);

  /**是否在请求中 */
  const hasloading = useRef(false);
  async function handel_collection(item) {
    if (hasloading.current) {
      return;
    }
    hasloading.current = true;
    try {
      let collection = !item.favorite;
      await FTImageStore.handel_collection(
        [item.id as string],
        collection,
        () => {
          setUpdateAnimation(true);
          clearTimeout(upadteTimer.current);
          upadteTimer.current = setTimeout(() => {
            setUpdateAnimation(false);
          }, 2000);
        }
      );
    } catch {}
    hasloading.current = false;
  }

  return (
    <>
      <div className='toolleft_effect_item_effect'>
        <img
          src={item.url}
          alt={item.name}
          className='loading-skeleton-back2'
        />
        <div className='toolleft_effect_item_check'></div>
        {(item.isHot || item.isNew) && (
          <div className={clsx(styles["tag"], item.isHot && styles["hot"])}>
            {item.isNew ? "NEW" : "HOT"}
          </div>
        )}
        {item.id === currentEffect?.id && (
          <div
            onContextMenu={(el) => {
              el.preventDefault();
            }}
            // onTouchStart={() => {
            //   timeRecords.current = performance.now();
            // }}
            // onTouchEnd={() => {
            //   //长按小于500算tips
            //   if (performance.now() - timeRecords.current < 500) {
            //     EventBusInstances.emit(EventName.CLEAR_TIPS);
            //     setShowTips(true);
            //     clearTimeout(timer.current as number);
            //     timer.current = setTimeout(() => {
            //       setShowTips(false);
            //     }, 3000);
            //     return;
            //   }
            //   handel_collection(item);
            // }}
            className={clsx(
              styles["collection"],
              item.id === currentEffect?.id && styles["collection-active"]
            )}>
            {updateAnimation && (
              <div className={styles["collection-animation"]}>
                <StarFillIcon width={16} />
                +1
              </div>
            )}
            <div
              onClick={(el) => {
                el.stopPropagation();
                el.preventDefault();
                handel_collection(item);
              }}
              className={styles["star-fra"]}>
              {item.favorite ? (
                <StarFillIcon width={16} />
              ) : (
                <StarIcon width={16} />
              )}
            </div>
          </div>
        )}
        {/* <div
          onClick={(el) => {
            el.stopPropagation();
            setShowPanel({
              visible: true,
              item: item
            });
          }}
          className={clsx(
            styles["more"],
            item.id === currentEffect?.id && styles["more-active"]
          )}>
          <MoreIcon width={16} />
        </div> */}
        {/* {currentEffect?.id === item.id && result !== EnumsShowStatus.origin && (
          <div
            onClick={(el) => {
              el.stopPropagation();
              delete FTImageStore.usedEffect[item.id];
              setEffectProcess(item, el);
            }}
            className={styles["regenerate"]}>
            <ReGenerateIcon width={14} />
          </div>
        )} */}
      </div>
      {/* <Tooltip
        overlayClassName={styles["tooltips-styles"]}
        title={t("goart_long_click_collection")}
        visible={showTips}>
        <div className='toolleft_effect_item_effect'>
          <img
            src={item.url}
            alt={item.name}
            className='loading-skeleton-back2'
          />
          <div className='toolleft_effect_item_check'></div>
          {(item.isHot || item.isNew) && (
            <div className={clsx(styles["tag"], item.isHot && styles["hot"])}>
              {item.isNew ? "NEW" : "HOT"}
            </div>
          )}
          <div
            onContextMenu={(el) => {
              el.preventDefault();
            }}
            onTouchStart={() => {
              timeRecords.current = performance.now();
            }}
            onTouchEnd={() => {
              //长按小于500算tips
              if (performance.now() - timeRecords.current < 500) {
                EventBusInstances.emit(EventName.CLEAR_TIPS);
                setShowTips(true);
                clearTimeout(timer.current as number);
                timer.current = setTimeout(() => {
                  setShowTips(false);
                }, 3000);
                return;
              }
              handel_collection(item);
            }}
            className={clsx(
              styles["collection"],
              item.id === currentEffect?.id && styles["collection-active"]
            )}>
            {updateAnimation && (
              <div className={styles["collection-animation"]}>
                <StarFillIcon width={16} />
                +1
              </div>
            )}
            {item.favorite ? (
              <StarFillIcon width={16} />
            ) : (
              <StarIcon width={16} />
            )}
          </div>
          <div
            onClick={() => {
              setShowPanel({
                visible: true,
                item: item
              });
            }}
            className={styles["more"]}>
            <MoreIcon width={16} />
          </div>
          {currentEffect?.id === item.id &&
            result !== EnumsShowStatus.origin && (
              <div
                onClick={(el) => {
                  el.stopPropagation();
                  delete FTImageStore.usedEffect[item.id];
                  setEffectProcess(item, el);
                }}
                className={styles["regenerate"]}>
                <ReGenerateIcon width={14} />
              </div>
            )}
        </div>
      </Tooltip> */}
      <span className='toolleft_effect_item_title'>{item.name}</span>
      {/* {(item.isNew || item.isHot) && (
        <div className='toolleft_effect_item_new'>
          {item?.isNew ? <IconNew /> : <IconHot />}
        </div>
      )} */}
    </>
  );
};

export default function ToolLeftMobile(props: IProps) {
  let { className } = props;
  let effectClassifyMap = useEffectClassifyMap();
  let choicenessList = useChoicenessList();
  let currentEffect = useCurrentEffect();
  let currentEffectTypeId = useCurrentEffectTypeId();
  let [effectList, setEffectList] = React.useState<IClassifyItem[]>([]);
  let effectRef = React.createRef<any>();
  let classify = React.createRef<any>();
  const [collectionList, setCollectionList] = useState<Array<IEffectItem>>([]);
  const { t } = useLanguage();
  const scrollRefBar = React.useRef<any>();

  const [result, setResult] = useState(FTImageStore.showStatus);

  useEffect(() => {
    let dispose = autorun(() => {
      setResult(FTImageStore.showStatus);
    });
    return () => {
      dispose();
    };
  }, []);

  React.useEffect(() => {
    setEffectList([
      // {
      //   title: "",
      //   list: effectClassifyMap.map((item) => item.list).flat(),
      //   key: "tag_all",
      //   id: "1",
      //   isNew: false,
      //   effectIds: [],
      //   lang: FTLang._("tag_all"),
      //   collapse: false
      // },
      ...effectClassifyMap
    ]);

    // let arr: Array<IEffectItem> = [];
    // for (let j = 0; j < FTImageStore.effectList.length; j++) {
    //   if (FTImageStore.effectList[j].favorite) {
    //     arr.push(FTImageStore.effectList[j]);
    //   }
    // }
    // setCollectionList(arr);
  }, [effectClassifyMap, choicenessList]);

  useEffect(() => {
    let dispose = autorun(() => {
      // if (FTUserStore.userInfo !== null) {
      //   if (
      //     !FTImageStore.loadingGlobalEffectNew &&
      //     FTImageStore.currentClassifyId === FAVOR
      //   ) {
      //     //更新一下
      //     FTImageStore.search();
      //   }
      // } else if (
      //   !FTUserStore.userInfo &&
      //   FTImageStore.currentClassifyId === FAVOR
      // ) {
      //   FTImageStore.searchList = [];
      // }
      setCollectionList(FTImageStore.searchList);
    });

    return () => {
      dispose();
    };
  }, []);

  useEffect(() => {
    if (currentEffect) {
      const id = currentEffect.id;
      const ele = document.querySelector(`.effect${id}`);
      if (ele) {
        const eleRect = ele.getBoundingClientRect();
        if (eleRect.left + eleRect.width > window.innerWidth) {
          effectRef.current &&
            (effectRef.current as any).scrollMove(
              "right",
              eleRect.left + eleRect.width * 2 - window.innerWidth
            );
        } else if (eleRect.left < 0) {
          effectRef.current &&
            (effectRef.current as any).scrollMove("left", -eleRect.left);
        }
      }
    }
    if (currentEffectTypeId) {
      const id = effectList.findIndex(
        (item) => item.id === currentEffectTypeId
      );
      if (id > 0) {
        const ele = document.getElementById(`classify_${effectList[id].id}`);
        if (ele) {
          const eleRect = ele.getBoundingClientRect();
          const scrollLeft = scrollRefBar.current?.scrollLeft ?? 0;

          if (eleRect.left + eleRect.width > window.innerWidth) {
            scrollRefBar.current?.scroll({
              left:
                eleRect.left +
                eleRect.width * 2 -
                window.innerWidth +
                scrollLeft,
              behavior: "smooth"
            });
          } else if (eleRect.left < 0) {
            scrollRefBar.current?.scroll({
              left: eleRect.left + scrollLeft,
              behavior: "smooth"
            });
          }
        }
      }
    }
  }, [currentEffect, currentEffectTypeId]);

  const setEffectProcess = (i: IEffectItem, e: any) => {
    if (FTImageStore.currentImage) {
      actionClickUp(utils.toFixed(i.id, 0), i.enName, {
        button_position: "goart_effect",
        category_id: i.classifyId,
        category_name: i.firstCategoryEn
      });
      //提前清除动画
      if (currentEffect?.id !== i?.id) {
        EventBusInstances.emit(EventName.CLEAR_TIPS);
      }
      FTImageStore.getGoartEffect(i);
      // 如果特效未完全显示，需要使特效完全显示
      let eleRect = e.target.getBoundingClientRect();
      if (eleRect.left + eleRect.width > window.innerWidth) {
        scrollRefBar.current &&
          (scrollRefBar.current as any).scrollMove?.(
            "right",
            eleRect.left + eleRect.width * 2 - window.innerWidth
          );
      } else if (eleRect.left < 0) {
        scrollRefBar.current &&
          (scrollRefBar.current as any).scrollMove?.("left", -eleRect.left);
      }
    } else{
      FTImageStore.currentEffect = i;
      FTImageStore.uploadImage("click_effect_upload");
    }
  };

  const handleOpenImage = async (e: any) => {
    if (e.target && e.target.classList.value.includes("open")) {
      FTImageStore.uploadImage("goart_left_change_image");
    }
  };

  const handleClick = (item: IClassifyItem, isFAVOR = false) => {
    //选中的favor
    if (isFAVOR) {
      //需要判断登录
      if (!FTUserStore.userInfo) {
        FTUserStore.showLogin({
          isSignIn: false,
          options: {
            position: "goart_tag_login"
          },
          isShowScenses: true,
          otherParam: {
            scenesId: "015"
          },
          callback: () => {
            FTImageStore.currentClassifyId = FAVOR;
            FTImageStore.search();
          }
        });
        return;
      }
    }
    FTImageStore.currentClassifyId = isFAVOR ? FAVOR : item.id;
    if (isFAVOR) {
      FTImageStore.search();
    }
    if (isFAVOR) {
      actionGroupClickUp(FAVOR, FAVOR);
    } else {
      actionGroupClickUp(item.id, item.enName);
    }
    setTimeout(() => {
      const span = document.querySelector(".toolleft_effect_title span.active");
      if (span) {
        const eleRect = span.getBoundingClientRect();
        const scrollLeft = scrollRefBar.current?.scrollLeft ?? 0;
        const scrollWidth = scrollRefBar.current?.scrollWidth ?? 0;
        const clientWidth = scrollRefBar.current?.clientWidth ?? 0;

        if (eleRect.left + eleRect.width > window.innerWidth) {
          scrollRefBar.current?.scroll({
            left:
              eleRect.left + eleRect.width * 2 - window.innerWidth + scrollLeft,
            behavior: "smooth"
          });
        } else if (eleRect.left < 0) {
          scrollRefBar.current?.scroll({
            left: eleRect.left + scrollLeft,
            behavior: "smooth"
          });
        }
      }
    });
  };
  const currentClass =
    currentEffectTypeId === "" ? effectList[0]?.id : currentEffectTypeId;

  /**缓存请求状态 */
  const cacheCollectionApiStatus = useRef({});

  async function handel_collection(el, item) {
    el.stopPropagation();
    if (cacheCollectionApiStatus.current[item.id]) {
      return;
    }
    cacheCollectionApiStatus.current[item.id] = true;
    await FTImageStore.handel_collection([item.id as string], !item.favorite);
    cacheCollectionApiStatus.current[item.id] = false;
  }
  const [update, setUpdate] = useState({});
  const [showPanel, setShowPanel] = useState<any>({
    visible: false,
    item: null
  });

  return (
    <div className={`toolleft toolleft_mobile ${className}`}>
      <div
        style={{
          display: showPanel.visible ? "block" : "none"
        }}
        className={styles["styles-info-fra"]}>
        <div
          className={clsx(
            styles["styles-inner"],
            showPanel.visible ? styles["styles-up"] : styles["styles-fade"]
          )}>
          <div className={clsx(styles["styles-title"])}>
            <span
              onClick={() => {
                setShowPanel({
                  visible: false,
                  item: null
                });
              }}>
              <IconClose width={12} />
            </span>
            {t("goart_mobile_style_info")}
          </div>
          <div className={styles["styles-content"]}>
            <p>{t("goart_mobile_style_name")}</p>
            <p>{showPanel.item?.name}</p>
            {showPanel.item?.tipsContent && (
              <>
                <p>{t("goart_mobile_style_tips")}</p>
                <p>{showPanel.item?.tipsContent}</p>
              </>
            )}
          </div>
        </div>
      </div>
      <FTScrollbar containerClassName={styles["scroll-styles"]}>
        <div className='toolleft_effect_title' ref={scrollRefBar}>
          <span
            onClick={() => handleClick({} as any, true)}
            id={`classify_${FAVOR}`}
            className={clsx(currentClass === FAVOR && "active")}>
            {t("goart_collection")}
          </span>
          {effectList &&
            effectList.map((item: any) => {
              return (
                <span
                  onClick={() => {
                    handleClick(item);
                    cache_click_red_tag(item);
                    setUpdate({});
                  }}
                  id={`classify_${item.id}`}
                  className={clsx(
                    currentClass === item.id && "active",
                    styles["title"]
                  )}>
                  {item.title}
                  {item.isNew &&
                    item.needShowNew &&
                    checkHasCLickRedTag(item.id) && (
                      <label className={styles["red-tag"]}></label>
                    )}
                </span>
              );
            })}
        </div>
      </FTScrollbar>
      <div className='toolleft_effect'>
        <div>
          {currentClass === FAVOR && (
            <FTScrollbar onRef={effectRef} className='toolleft_effect_scroll'>
              <div className='toolleft_effect_scroll_list'>
                {collectionList.length === 0 && (
                  <div className={styles["effect-seat"]}>
                    <FolderIcon width={98} />
                    <p>{t("goart_collection_seat_title1")}</p>
                    <p>{t("goart_collection_seat_title2")}</p>
                  </div>
                )}
                {collectionList.map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      className={`toolleft_effect_item effect${item.prodid || item.id} ${item.id === currentEffect?.id ? "toolleft_effect_item_active" : ""}`}
                      onClick={(e) => setEffectProcess(item, e)}>
                      <InnerContent
                        setShowPanel={setShowPanel}
                        classifyItem={item.classifyItem}
                        setEffectProcess={setEffectProcess}
                        item={item}
                        result={result}
                        currentEffect={currentEffect}
                      />
                      {/* <div className='toolleft_effect_item_effect'>
                        <img
                          src={item.url}
                          alt={item.name}
                          className='loading-skeleton-back2'
                        />
                        <div className='toolleft_effect_item_check'></div>
                        <div
                          onClick={async (el) => {
                            el.stopPropagation();
                            handel_collection(el, item);
                          }}
                          className={clsx(
                            styles["collection"],
                            item.id === currentEffect?.id &&
                              styles["collection-active"]
                          )}>
                          {item.favorite ? (
                            <StarFillIcon width={16} />
                          ) : (
                            <StarIcon width={16} />
                          )}
                        </div>
                        {currentEffect?.id === item.id &&
                          result !== EnumsShowStatus.origin &&
                          item?.aigc && (
                            <div
                              onClick={(el) => {
                                el.stopPropagation();
                                setEffectProcess(item, el);
                              }}
                              className={styles["regenerate"]}>
                              <ReGenerateIcon width={14} />
                            </div>
                          )}
                      </div>
                      <span className='toolleft_effect_item_title'>
                        {item.name}
                      </span> */}
                    </div>
                  );
                })}
                <div
                  style={{
                    width: "16px",
                    height: "1px",
                    flexShrink: 0
                  }}></div>
              </div>
            </FTScrollbar>
          )}
          {effectList &&
            effectList.map((classifyItem) => {
              if (classifyItem.id === currentClass) {
                return (
                  <FTScrollbar
                    onRef={effectRef}
                    className='toolleft_effect_scroll'>
                    <div className='toolleft_effect_scroll_list'>
                      {classifyItem.list.map((item: any) => {
                        return (
                          <div
                            key={item.id}
                            className={`toolleft_effect_item effect${item.prodid || item.id} ${item.id === currentEffect?.id ? "toolleft_effect_item_active" : ""}`}
                            onClick={(e) => setEffectProcess(item, e)}>
                            {/* <div className='toolleft_effect_item_effect'>
                              <img
                                src={item.url}
                                alt={item.name}
                                className='loading-skeleton-back2'
                              />
                              <div className='toolleft_effect_item_check'></div>
                              {(item.isHot || item.isNew) && (
                                <div
                                  className={clsx(
                                    styles["tag"],
                                    item.isHot && styles["hot"]
                                  )}>
                                  {item.isNew ? "NEW" : "HOT"}
                                </div>
                              )}
                              <div
                                onClick={(el) => {
                                  el.stopPropagation();
                                  handel_collection(el, item);
                                }}
                                className={clsx(
                                  styles["collection"],
                                  item.id === currentEffect?.id &&
                                    styles["collection-active"]
                                )}>
                                {item.favorite ? (
                                  <StarFillIcon width={16} />
                                ) : (
                                  <StarIcon width={16} />
                                )}
                              </div>
                              {currentEffect?.id === item.id &&
                                result !== EnumsShowStatus.origin && (
                                  <div
                                    onClick={(el) => {
                                      el.stopPropagation();
                                      delete FTImageStore.usedEffect[item.id];
                                      setEffectProcess(item, el);
                                    }}
                                    className={styles["regenerate"]}>
                                    <ReGenerateIcon width={14} />
                                  </div>
                                )}
                            </div>
                            <span className='toolleft_effect_item_title'>
                              {item.name}
                            </span>
                            {(item.isNew || item.isHot) && (
                              <div className='toolleft_effect_item_new'>
                                {item.isHot && <IconHot />}
                                {item.isNew && classifyItem.needShowNew && (
                                  <IconNew />
                                )}
                              </div>
                            )} */}
                            <InnerContent
                              setShowPanel={setShowPanel}
                              classifyItem={classifyItem}
                              setEffectProcess={setEffectProcess}
                              item={item}
                              result={result}
                              currentEffect={currentEffect}
                            />
                          </div>
                        );
                      })}
                      <div
                        style={{
                          width: "16px",
                          height: "1px",
                          flexShrink: 0
                        }}></div>
                    </div>
                  </FTScrollbar>
                );
              } else {
                return <></>;
              }
            })}
        </div>
      </div>
    </div>
  );
}
