/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-02 16:55:10
 * @Description:
 */

import "./FTDownloadLoading.scss";

import { AD_ID, FTLang, rangNumber } from "src/utils";
import { FTCanvasStore, FTImageStore } from "src/store";
import React, { useEffect, useState } from "react";
import { useIsMobile, useLanguage } from "src/hooks";

import ADWrapper from "../ADWrapper/ADWrapper";
import FTButton from "../FTButton";
import FTProgress from "../FTProgress/FTProgress";
import FotorLoading from "../FotorLoading";
import { IconClose } from "src/assets";
import ReactDOM from "react-dom";
import { autorun } from "mobx";
import clsx from "clsx";
import { urls } from "src/constant";

interface IProps {
  loading: boolean;
  progress?: number;
}
function FTDownloadLoading(props: IProps) {
  const { loading, progress } = props;
  const [percent, setPercent] = React.useState(progress || 0);
  const isMobile = useIsMobile();
  const timer = React.useRef<any>(null);
  const [adShow, setAdShow] = useState(false);
  const [isAigc, setIsAigc] = useState(false);
  const { t } = useLanguage();
  // const startInterval = () => {
  //   if(timer.current) clearInterval(timer.current);

  //   timer.current = setInterval(() => {
  //     setPercent((percent) => {
  //       percent = percent + 3;
  //       return rangNumber(0, 100, percent);
  //     });
  //   }, 1000);
  // }
  // const setDownLoadVisible = useCanvasStoreFN(state=>state.setDownLoadVisible)
  // React.useEffect(() => {
  //   startInterval();
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }, []);
  React.useEffect(() => {
    setPercent(progress || 0);
  }, [progress]);
  React.useEffect(() => {
    if (!loading) {
      clearInterval(timer.current);
      timer.current = null;
    }
    const downloadAgain = document.querySelector(".download_again");
    downloadAgain?.addEventListener("click", () => {
      // setPercent(2);
      // startInterval();

      FTCanvasStore.downloadFullSize();
    });
  }, [loading]);
  React.useEffect(() => {
    const dispose = autorun(() => {
      setIsAigc(FTImageStore.currentEffect?.aigc ?? false);
    });
    return () => {
      dispose();
    };
  }, []);

  const handleClose = () => {
    FTCanvasStore.setDownLoadVisible(false);
  };
  const handleToVideo = () => {
    const {
      artUploadImageUrl,
      uploadImageUrl,
      effectElement,
      artDownloadImageUrl,
      effectDownloadImageUrl,
      currentEffect
    } = FTImageStore;
    const isAigc = currentEffect.aigc;
    let isProd = process.env.REACT_APP_ENVIRONMENT === "production";
    const resultUrl = isAigc ? effectDownloadImageUrl : artDownloadImageUrl;
    const originUrl = isAigc ? uploadImageUrl : artUploadImageUrl;
    // https://test-www.fotor.com/apps/ai-video-generator/?type=photo&style=04c2781d-cb64-499d-9542-04788e834665&imageurl=null&rightImageUrl=https://test-u-static.fotor.com/uid_73ab1490ef994488bf8f044a711dfd73/goart-effects/a1b5e76df90347f2945793d4d2aca425.jpg&quality=2&videoDuration=4&generateTemplateId=67aaf23e5f13000089006287
    const link =
      urls.baseUrl +
      `apps/ai-video-generator/?type=photo&imageurl=${originUrl}&rightImageUrl=${resultUrl}&generateTemplateId=${isProd ? "67bbeae0289a080022a4b091" : "67aaf23e5f13000089006287"}`;
    console.log("link", link);
    window.open(link, "_blank");
  };
  const newProgress = rangNumber(0, 100, percent);
  const handleAdCallback = (res) => {
    console.log("handleAdCallback", res);
    if (res.adDomId === AD_ID.EXPORT && res.type === "loaded") {
      setAdShow(res.haveAd);
    }
  };
  return ReactDOM.createPortal(
    <div className='layout_effect_loading'>
      <div
        className={clsx(
          "download_loading",
          isMobile && "download_loading_mobile"
        )}>
        <div
          className={clsx("download_loading_header", "loading-skeleton")}
          style={{ display: adShow ? "flex" : "none" }}>
          {/* <ADWrapper
          adId={AD_ID.EXPORT}
          type='export'
          callback={handleAdCallback}
        /> */}
        </div>
        <div
          className={clsx(
            "download_loading_content",
            loading ? "loading-current" : ""
          )}>
          {loading ? (
            <>
              <FotorLoading />
              <div className='download_loading_text'>
                {t("goart_downloading")}
              </div>
              <div className='download_loading_process_wrapper'>
                <div className='download_loading_process'>
                  <FTProgress
                    progress={newProgress}
                    startColor='#26FFA5'
                    endColor='#2643FF'
                  />
                </div>
                <div className='download_loading_process_text'>
                  {newProgress} %
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='download_loading_title'>
                {t("download_success_title")}
              </div>
              <div
                className='download_loading_desc'
                dangerouslySetInnerHTML={{
                  __html: t("download_success_desc", {
                    link: `<span class='download_again'>${t("download_success_link")}</span>`
                  })
                }}></div>
              <div className='download_loading_btn'>
                <FTButton type='primary' onClick={handleClose}>
                  {t("download_success_continue")}
                </FTButton>
              </div>
            </>
          )}
        </div>
        {!loading && (
          <div className='download_loading_close' onClick={handleClose}>
            <IconClose />
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}

export default FTDownloadLoading;
