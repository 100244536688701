import { IconClose, TimeoutIcon } from "src/assets";
import styles from "./index.module.scss";
import { FTImageStore } from "src/store";
import { useIsMobile, useLanguage } from "src/hooks";
const TimeoutPop = () => {
  const { t } = useLanguage();
  const isMobile = useIsMobile();

  return (
    <div
      onClick={(el) => {
        if (isMobile) {
          FTImageStore.hasShowTimeoutPopObj = {
            visible: false,
            id: ""
          };
        }
      }}
      className={styles["container"]}>
      <div
        onClick={(el) => {
          el.stopPropagation();
        }}
        className={styles["inner"]}>
        <div
          onClick={() => {
            FTImageStore.hasShowTimeoutPopObj = {
              visible: false,
              id: ""
            };
          }}
          className={styles["close"]}>
          <IconClose width={14} />
        </div>
        <div className={styles["time-out"]}>
          <TimeoutIcon width={48} />
          <div className={styles["title-fra"]}>
            <p className={styles["title"]}>{t("network_error_title")}</p>
            <p className={styles["des"]}>{t("network_error_content")}</p>
          </div>
        </div>
        <div className={styles["button-fra"]}>
          <div
            onClick={() => {
              FTImageStore.hasShowTimeoutPopObj = {
                visible: false,
                id: ""
              };
            }}>
            {t("network_error_retry")}
          </div>
          <div
            onClick={() => {
              FTImageStore.getGoartEffect(
                FTImageStore.hasShowTimeoutPopObj.id,
                "retry_button"
              );
              FTImageStore.hasShowTimeoutPopObj = {
                visible: false,
                id: ""
              };
            }}>
            {t("network_error_cancel")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeoutPop;
