/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-11-29 20:05:55
 * @Description:
 */

import FTEventTarget, { FTEVENT_CONSTANTS } from "src/utils/FTEventTarget";
import { FTGetSubscriptions, FTGetUserPanelType } from "src/server/api";
import { FTLang, FTSaveLocalData, getABTestGroup, utils } from "src/utils";
import { configure, observable } from "mobx";

import Cookies from "js-cookie";
import { FTGetAdShowCountry } from "src/server/api";
import FTImageStore from "src/store/FTImageStore";
import { FTUserStoreType } from "./types";

// mobx 配置
configure({
  enforceActions: "never" // 允许直接修改状态
});

Cookies.set("user_status", 0, {
  domain: ".fotor.com", // 设置域名（跨子域名）
  path: "/", // 设置路径，根目录生效
  secure: true,
  expires: 7,
  sameSite: "None"
});

const FTUserStore = observable.object<FTUserStoreType>({
  blackFridayEvent: "none",
  language: utils.getCookie("locale") || utils.getCurrentLanguage() || "en_US",
  userInfo: null,
  isVip: false,
  isSvip: false,
  showFreeTry: false,
  showDiscount: 0,
  favortype: "",
  showCountryAd: false,
  get activeBlackFriday() {
    // if (!this.userInfo) {
    //   return this.blackFridayGroupB;
    // }

    // if (this.isVip) {
    //   return false;
    // }

    // if (this.userInfo.registerDays < 3) {
    //   return this.blackFridayGroupB;
    // } else {
    //   return true;
    // }

    // if(!this.isVip && this.userInfo?.registerDays >= 3){
    //   this.showDiscount = 66;
    //   return true;
    // }
    if (this.isVip) {
      return false;
    }
    // 新需求，所有非用户都显示黑五活动
    return this.favortype === "discount";
  },
  get blackFridayGroupB() {
    return this.blackFridayEvent.toLowerCase() === "b";
  },
  get blackFridayGroupA() {
    return this.blackFridayEvent.toLowerCase() === "a";
  },
  get isLogin() {
    return !!this.userInfo;
  },

  get userUniqueKey(){
    return this.userInfo?.fotorToken || "";
  },

  getUserSuffiexKey(key:string){
    const userUniqueKey = this.userUniqueKey;
    return `${key}_${userUniqueKey}`;
  },

  setLanguage(lang: string) {
    this.language = lang;
    FTLang.currentLanguage = lang;
    FTSaveLocalData("language", lang);
  },
  getShowCountryAd() {
    FTGetAdShowCountry().then((res) => {
      let { code, data } = res;
      if (code === "000") {
        this.showCountryAd = res.data.block;
      } else {
        this.showCountryAd = false;
      }
    });
  },
  setUserInfo(data: any) {
    if (this.userInfo?.fotorToken !== data?.fotorToken) {
      FTEventTarget.getInstance().fireEvent({
        type: FTEVENT_CONSTANTS.USERINFO_CHANGED,
        data: data
      });
    }
    this.userInfo = data;
  },
  setIsVip(bool: boolean) {
    // 只关注升级VIP
    if (!this.isVip && this.isVip !== bool) {
      FTEventTarget.getInstance().fireEvent({
        type: FTEVENT_CONSTANTS.USER_VIP_CHANGED,
        data: bool
      });
    }
    this.isVip = bool;
  },
  setIsSvip(bool: boolean) {
    this.isSvip = bool;
  },
  showLogin({
    isSignIn,
    options,
    isShowScenses,
    otherParam,
    callback,
    errorCallBack
  }) {
    utils.loadLoginScript().then(() => {
      let signType = isSignIn ? "login" : "signUp";
      let position = (options && options.position) || "";
      window.login &&
        window.login.showLogin(
          signType,
          (res: any) => {
            this.loginCallback(res, callback, errorCallBack);
          },
          "",
          { position },
          isShowScenses,
          { ...otherParam }
        );
    });
  },

  async loginCallback(res, callback, errorCallBack) {
    let { code, userInfo } = res;
    if (code === 0 || code === 1 || code === 2) {
      FTImageStore.initTaskList();
      // 登陆成功回调
      this.setUserInfo(userInfo.data);
      this.getUserActivity();
      this.getShowCountryAd();
      this.checkUserVip(callback);
      // this.startAbTest();
    } else {
      errorCallBack && errorCallBack(res);
    }
  },
  startAbTest() {
    getABTestGroup({
      param_name: "black_friday",
      default_value: "",
      value_type: "String",
      callbackA: (result) => {
        console.log(
          "=================================================>A方案",
          result
        );
        this.blackFridayEvent = "a";
        //这里可以加入埋点 this.handleAnalyticAB({ actionType: "none", type: "A" });
      },
      callbackB: (result) => {
        this.blackFridayEvent = "b";
        console.log("==========================================>B方案", result);
      }
    });
  },
  loginOut() {
    window.login.FTLogout().then(() => {
      this.setUserInfo(null);
      this.setIsVip(false);
      this.setIsSvip(false);
      this.getUserActivity();
      this.getShowCountryAd();
      this.blackFridayEvent = "none";
      FTImageStore.taskList = [];
      FTImageStore.imageHD = false;
      FTImageStore.loadingGlobalEffectNew = false;
      //清除收藏
      FTImageStore.clearAllCollectionSamping();
    });
  },

  checkUserLogin(callback: any, callBack1?) {
    utils.loadLoginScript().then(() => {
      window.login &&
        window.login.FTGetLoginState().then(async (res: any) => {
          if (res && res.code === "000") {
            //登陆前清除副作用
            FTImageStore.clearAllCollectionSamping();
            this.setUserInfo(res.data);
            this.getShowCountryAd();
            await this.checkUserVip(callback);
            this.getUserActivity();
            // this.startAbTest();
          } else {
            //标注是请求了未登录
            this.userInfo = undefined;
            callBack1 && callBack1();
          }
        });
    });
  },

  checkUserVip(callback) {
    return FTGetSubscriptions()
      .then((res: any) => {
        if (res && res.code === "000") {
          let sub = res.data || [];
          const hasVip =
            sub.find((m: any) => m.productName === "fotor" && !m.expiration) ||
            this.userInfo.superFotor;
          const hasSvip = sub.find(
            (m: any) => m.productName === "pxbee" && !m.expiration
          );

          Cookies.set("user_status", hasSvip ? 3 : hasVip ? 2 : 1, {
            domain: ".fotor.com", // 设置域名（跨子域名）
            path: "/", // 设置路径，根目录生效
            secure: true,
            expires: 7,
            sameSite: "None"
          });
          if (hasSvip) {
            this.setIsVip(true);
            this.setIsSvip(true);
          } else if (hasVip) {
            this.setIsVip(true);
          } else {
            this.setIsVip(false);
            this.setIsSvip(false);
          }

          if (hasVip) {
            FTImageStore.imageHD = true;
          } else {
            FTImageStore.imageHD = false;
          }
        }
      })
      .finally(() => {
        callback && callback();
      });
  },
  showPaymentBase({
    vipType,
    position,
    otherParam,
    callback,
    errorCallBack,
    payType
  }) {
    utils.loadPaymentSdk().then(() => {
      if (window["Payment"]) {
        // * @param callback  回调参数
        // * @param priceTabId tabIndex 支付面板  0非vip选中pro  1非vip选中pro+ 2是pro升级到pro+ 默认为1
        // * @param fromFunction edit,design,college,other
        // * @param app_id 埋点用唯一的id
        // * @param id  domid(可选)
        // * @param sensorsData {position:''} 神策埋点数据
        window["Payment"].showPayment(
          (data: any) => {
            this.paymentCallback(data, callback, errorCallBack);
          },
          vipType,
          payType || "other",
          "",
          "",
          { position, project_type: "goart" },
          otherParam
        );
      }
    });
  },
  showPaymnet({
    vipType,
    position,
    otherParam,
    callback,
    errorCallBack,
    isFree,
    payType
  }) {
    let isProd = process.env.REACT_APP_ENVIRONMENT === "production";
    // if(!otherParam.promotionId) {
    //   otherParam.promotionId = isProd ? 606 : 574
    // }
    const otherParamInit = {
      ...otherParam
    };

    if (isFree) {
      otherParamInit.promotionId = isProd ? "665" : "627";
    } else if (this.activeBlackFriday) {
      otherParamInit.promotionId = isProd ? "2886" : "818";
      vipType = "svip";
    }

    utils.loadPaymentSdk().then(() => {
      if (window["Payment"]) {
        // * @param callback  回调参数
        // * @param priceTabId tabIndex 支付面板  0非vip选中pro  1非vip选中pro+ 2是pro升级到pro+ 默认为1
        // * @param fromFunction edit,design,college,other
        // * @param app_id 埋点用唯一的id
        // * @param id  domid(可选)
        // * @param sensorsData {position:''} 神策埋点数据
        window["Payment"].showPayment(
          (data: any) => {
            this.paymentCallback(data, callback, errorCallBack);
          },
          vipType === "svip" ? 1 : 0,
          payType ? payType : "other",
          "",
          "",
          { position, project_type: "goart" },
          otherParamInit
        );
      }
    });
  },

  async paymentCallback(data, callback, errorCallback) {
    // -----------国外支付弹窗返回------------
    // {type:'fail',code:'001',action:'close'} 支付失败 ，001 价格面板，行为关闭
    // {type:'fail',code:'004',action:'close'} 支付失败 ，004stripe 面板，行为关闭
    // {type:'fail',code:'005',action:'close'} 支付失败 ，005 兑换效验成功页面，行为关闭

    // {type:'success',code:'006',action:'close'} 领取兑换码成功，006 兑换领取成功页面，行为关闭
    // {type:'success',code:'006',action:'complete'} 领取兑换码成功，006 兑换领取成功页面，行为点击完成按钮
    // {type:'fail',code:'007',action:'close'} 领取兑换码失败，007 兑换领取成功页面，行为关闭
    // {type:'fail',code:'007',action:'complete'} 领取兑换码失败，007 兑换领取成功页面，行为点击取消

    // {type:'success',code:'008',action:'close'} 购买成功,008 购买成功页面，行为关闭
    // {type:'success',code:'008',action:'complete'} 购买成功,008 购买成功页面，行为点击购买成功按钮
    let { type, code } = data;

    if (code === "006" || code === "007") {
      // 兑换码回调，不是支付，不管
      // await FTBgremoveStore.updateCredits()
      this.checkUserLogin(callback);
      return;
    } else if (type === "success") {
      this.getUserActivity();
      this.checkUserLogin(callback);
      FTImageStore.imageHD = true;
    } else if (type === "fail") {
      errorCallback && errorCallback();
    }
  },
  get504ErrorMsg() {
    if (this.isSvip) {
      return "goart_aigc_root_maxs";
    }
    if (this.isVip) {
      return "error_504_pro";
    }
    if (!this.userInfo) {
      return "error_504_useinfo_nill";
    }
    return "error_504_common";
  },
  getUserActivity() {
    this.showDiscount = 0;
    this.showFreeTry = false;
    this.blackFridayEvent = "none";
    this.favortype = "";
    return FTGetUserPanelType().then((res) => {
      if (res && res.code === "000") {
        let { activity, vipStatus } = res.data;
        if (!activity) {
          this.showDiscount = 0;
          this.showFreeTry = false;
          this.blackFridayEvent = "none";
        } else {
          const { abTestParaName, favorType } = activity;
          this.favortype = favorType;

          if (["try", "try_discount"].includes(activity.favorType)) {
            if (this.isVip) {
              this.showDiscount = 0;
              this.showFreeTry = false;
            } else {
              this.showDiscount = 0;
              this.showFreeTry = true;
            }
          } else if (activity.favorType === "discount" && vipStatus === 0) {
            this.showFreeTry = false;
            this.showDiscount = activity.discountPercent;
          } else {
            this.showFreeTry = false;
            this.showDiscount = 0;
          }
        }
      }
    });
  }
});

export default FTUserStore;
