import "./ToolLeftEffectList.scss";

import FTEventTarget, { FTEVENT_CONSTANTS } from "src/utils/FTEventTarget";
/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 09:36:55
 * @Description:
 */
import React, { useRef } from "react";
import {
  useCurrentEffect,
  useEffectClassifyMap,
  useImageStoreFN,
  useSearchList,
  useSearchLoading,
  useSearchText
} from "src/hooks";

import EffectListCollapse from "./EffectListCollapse/EffectListCollapse";
import { FTImageStore } from "src/store";
import { FTScrollbar } from "src/components";
import ToolLeftSearchList from "src/pages/ToolLeft/ToolLeftSearchList/ToolLeftSearchList";
import loadable from "@loadable/component";
import { FAVOR } from "src/constant";

const FTGuid = loadable(() => import("../FTGuid/FTGuid"));

export default function ToolLeftEffectList() {
  const scrollRefDom: any = useRef(null);
  const scrollRefInner: any = useRef(null);
  /**全部的style */
  let effectListMap = useEffectClassifyMap();
  const currentClassifyId = useImageStoreFN((item) => item.currentClassifyId);
  let searchList = useSearchList();
  const searchLoading = useSearchLoading();
  let [searchText] = useSearchText();
  let currentEffect = useCurrentEffect();
  let scrollRef = React.createRef();
  const timerRef = React.useRef<any>();
  const loadingGlobalEffect = useImageStoreFN(
    (store) => store.loadingGlobalEffect
  );
  const showGuid = useImageStoreFN((state) => state.showGuid);
  // const { t } = useLanguage();

  const handleUpload = () => {
    FTImageStore.uploadImage("goart_left_change_image");
  };
  let [init, setInit] = React.useState(false);

  // console.log("===currentClassify", currentClassify);

  React.useEffect(() => {
    const handle = (data) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      timerRef.current = setTimeout(() => {
        handleToScrollView(data);
      }, 500);
    };
    FTEventTarget.getInstance().addEvent(
      FTEVENT_CONSTANTS.SCROLL_TO_VIEW,
      handle
    );
    return () => {
      FTEventTarget.getInstance().removeEvent(
        FTEVENT_CONSTANTS.SCROLL_TO_VIEW,
        handle
      );
    };
  }, []);

  React.useEffect(() => {
    if (currentEffect && !init) {
      setInit(true);
      let ele = document.querySelector(
        `.${currentEffect.name.replace(/ /g, () => "_")}`
      );
      let eleRect = ele && ele.getBoundingClientRect();
      let scrollRect = (
        scrollRef.current as unknown as any
      ).current.getBoundingClientRect();
      if (eleRect && scrollRect) {
        if (eleRect.top - scrollRect.top - scrollRect.height > -200) {
          (scrollRef.current as unknown as any).scrollMove(
            "bottom",
            eleRect.top - scrollRect.top - scrollRect.height / 2
          );
        }
      }
    }
  }, [currentEffect, init, effectListMap]);

  // React.useEffect(() => {
  //   if (effectListMap.length) {
  //     if (FTImageStore.defaultEffectClassify) {
  //       let ele = document.getElementById(FTImageStore.defaultEffectClassify);
  //       let eleRect = ele && ele.getBoundingClientRect();
  //       let scrollRect = (
  //         scrollRef.current as unknown as any
  //       ).current.getBoundingClientRect();
  //       if (eleRect && scrollRect) {
  //         if (eleRect.top - scrollRect.top - scrollRect.height > -200) {
  //           (scrollRef.current as unknown as any).scrollMove(
  //             "bottom",
  //             eleRect.top - scrollRect.top
  //           );
  //         }
  //       }
  //       FTImageStore.defaultEffectClassify = "";
  //     }
  //   }
  // }, [effectListMap]);

  // React.useEffect(() => {
  //   if (scrollRef.current) {
  //     let current = scrollRef.current as unknown as any;
  //     current.scrollMove("top", current.current.scrollTop);
  //   }
  // }, [searchList]);

  const handleToScrollView = (data) => {
    const scrollDom = document.querySelector(".effectScroll");
    if (scrollDom) {
      // 获取scrollDom的第一个子元素
      const firstChild = scrollDom.firstElementChild?.firstElementChild;
      if (firstChild) {
        const { scrollHeight, clientHeight, scrollTop } = firstChild;
        const e = document.querySelector(
          `#fotor_${data?.data?.id}`
        ) as HTMLDivElement;
        if (e) {
          const top = e.offsetTop;
          const rect = e.getBoundingClientRect();
          // 在可视区域内
          let isInside =
            top >= scrollTop && top + rect.height <= scrollTop + clientHeight;
          if (!isInside) {
            firstChild?.scrollBy({
              top: top - rect.height,
              behavior: "smooth"
            });
          }
        }
      }
    }
  };

  /**纯查询结果 */
  let searchTextMatch =
    searchText
      .toLocaleLowerCase()
      .trim()
      .split(/[ |,]/g)
      .map((item) => item.trim())
      .filter((item) => item).length > 0;
  const currentClassify: string | { needShowNew: boolean } | undefined =
    currentClassifyId === FAVOR
      ? FAVOR
      : currentClassifyId
        ? effectListMap.find((item) => item.id === currentClassifyId)
        : undefined;
  const showNew =
    (currentClassify as { needShowNew: boolean })?.needShowNew ?? false;
  return (
    <div className='toolleft_effect_list' ref={scrollRefDom}>
      <FTScrollbar onRef={scrollRef} theme='white' className='effectScroll'>
        {/* //这个是全部的列表 */}
        {!searchTextMatch && searchList.length === 0 && !currentClassifyId && (
          <div className='toolleft_effect_list_scroll' ref={scrollRefInner}>
            {effectListMap.map((classifyItem, index: number) => {
              return (
                <EffectListCollapse
                  key={classifyItem.id}
                  effectList={classifyItem.list}
                  title={classifyItem.title}
                  isCollapse={true}
                  needCollapse={false}
                  id={classifyItem.id}
                  showNew={classifyItem.needShowNew}
                />
              );
            })}
          </div>
        )}
        {currentClassifyId && (
          <ToolLeftSearchList
            // 判断是不是收藏列表
            noNeedSeat={currentClassifyId === FAVOR}
            searchList={searchList}
            searchKey={""}
            searchLoading={searchLoading}
            showTitle={false}
            currentClassify={currentClassifyId}
            showNew={showNew}
          />
        )}

        {searchTextMatch && (
          <ToolLeftSearchList
            searchList={searchList}
            searchKey={searchText}
            searchLoading={searchLoading}
          />
        )}
        {loadingGlobalEffect && (
          <ToolLeftSearchList
            searchList={[]}
            searchKey={""}
            searchLoading={true}
          />
        )}
        <div style={{ height: "20px" }}></div>
        {/* {!uploadImage && (
            <div className='toolleft_mask' onClick={handleUpload} style={{ height: `calc(${scrollRefInner?.current?.clientHeight}px - ${scrollRefDom?.current?.clientHeight}px)` }}></div>
          )} */}
      </FTScrollbar>
      {showGuid && <FTGuid />}
    </div>
  );
}
