import { MAX_WIDTH, SIZE_LIMIT } from "src/constant";

import constUrl from "src/constant/url";

export enum LANGUAGE_TYPE {
  ZH_CN = "zh_CN",
  ZH_TW = "zh_TW",
  EN_US = "en_US",
  ES_ES = "es_ES",
  PT_BR = "pt_BR",
  RU_RU = "ru_RU",
  FR_FR = "fr_FR",
  DE_DE = "de_DE",
  JA_JP = "ja_JP",
  ID_ID = "id_ID",
  IT_IT = "it_IT",
  TH_TH = "th_TH",
  VI_VN = "vi_VN",
  TR_TR = "tr_TR",
  PL_PL = "pl_PL",
  NL_NL = "nl_NL",
  KO_KR = "ko_KR"
}
interface uploadProps {
  accept?: string;
  multiple?: boolean;
  useFocus?: boolean;
}

interface Size {
  width: number;
  height: number;
}

const utils = {
  loadJsScript(url: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const bodyScript = document.createElement("script");
      bodyScript.setAttribute("src", url);
      bodyScript.defer = true;
      bodyScript.onload = function onLoad() {
        resolve();
      };
      bodyScript.onerror = function onLoad() {
        resolve();
      };
      document.head.appendChild(bodyScript);
    });
  },
  loadPaymentSdk(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (window.Payment) {
        resolve();
      } else {
        const bodyScript = document.createElement("script");
        bodyScript.setAttribute("src", constUrl.paymentSdk as string);
        bodyScript.onload = function () {
          resolve();
        };
        document.body.appendChild(bodyScript);
      }
    });
  },
  getImageFileFromUrl(url: string, imageName: any) {
    let p = new Promise((resolve, reject) => {
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Accept", "image/jpeg");
      xhr.responseType = "blob";
      xhr.onload = () => {
        blob = xhr.response;
        let imgFile = new File([blob!], imageName, { type: "image/jpeg" });
        resolve(imgFile);
      };
      xhr.onerror = () => {
        resolve(false);
      };
      xhr.send();
    });
    return p;
  },
  loadLoginScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (window.login) {
        resolve();
      } else {
        const bodyScript = document.createElement("script");
        bodyScript.setAttribute("src", constUrl.loginSdk as string);
        bodyScript.onload = function () {
          resolve();
        };
        document.body.appendChild(bodyScript);
      }
    });
  },

  //加载移动端公共组件脚本
  loadMobileCommonScript() {
    if (!utils.myBrowserOS().isMobile) {
      return null;
    }
    return utils.loadJsScript(process.env.REACT_APP_MOBILE_COMMON_SDK!);
  },

  /**
   * 自动显示[open app]弹窗
   */
  autoShowOpenAppConfirm() {
    if (utils.myBrowserOS().isMobile) {
      //移动端公共组件库，载入完成后回调
      utils.mobileCommonSdkLoaded(() => {
        window.mobileCommon.scheduleTaskByTimes(
          "goart_confirm_popup_show",
          () => {
            const { FTOpenAppConfirmInstance } = window.mobileCommon;
            //立即显示[open app]弹窗 默认 1.2 7
            FTOpenAppConfirmInstance().show({
              projectType: "goart"
            });
          }
        );
      });
    }
  },
  myBrowserOS() {
    let u = navigator.userAgent;
    let uLower = navigator.userAgent.toLowerCase();
    return {
      //移动终端浏览器版本信息
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      mac: u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      isMobile: /Android|webOS|iPhone|iPod|BlackBerry/i.test(u),
      isSafari: !!uLower.match(/version\/([\d.]+).*safari/),
      isOpera: !!uLower.match(/opera.([\d.]+)/),
      isChrome: !!uLower.match(/chrome\/([\d.]+)/),
      isFirefox: !!uLower.match(/firefox\/([\d.]+)/),
      isEdge: !!uLower.match(/edge\/([\d.]+)/),
      isIE:
        !!uLower.match(/msie ([\d.]+)/) ||
        !!uLower.match(/rv:([\d.]+)\) like gecko/)
    };
  },
  checkIsMobile() {
    if (typeof window === "undefined") return false;
    const uaMobile = utils.myBrowserOS().isMobile;
    let widthMobile = false;
    try {
      widthMobile = (window?.innerWidth ?? 0) <= 750;
    } catch (error) {
      console.warn("why has not window?", error);
    }
    return uaMobile || widthMobile;
  },

  /**
   * 移动端公共组件库SDK载入完成后执行回调
   * @param callback
   * @returns
   */
  mobileCommonSdkLoaded(callback: (sdk: any) => void) {
    //如果已载入立即执行
    if (window.mobileCommon) {
      callback && callback(window.mobileCommon);
      return () => {};
    }

    function stop() {
      //移除监听
      window.removeEventListener("mobile_common_sdk_loaded", loadedHandle);
    }

    function loadedHandle() {
      callback && callback(window.mobileCommon);
      stop();
    }

    //创建 SDK载入完成事件监听
    window.addEventListener("mobile_common_sdk_loaded", loadedHandle);

    return stop;
  },

  formatEffectList(effectList: any[]): any[] {
    let newEffect: any[] = [];
    while (effectList.length > 0) {
      newEffect.push(effectList.splice(0, 3));
    }
    return newEffect;
  },

  getLanguageSmallKey(languageType: LANGUAGE_TYPE) {
    switch (languageType) {
      case LANGUAGE_TYPE.ZH_CN:
        return "cn";
      case LANGUAGE_TYPE.ZH_TW:
        return "tw";
      case LANGUAGE_TYPE.EN_US:
        return "us";
      case LANGUAGE_TYPE.ES_ES:
        return "es";
      case LANGUAGE_TYPE.PT_BR:
        return "pt";
      case LANGUAGE_TYPE.RU_RU:
        return "ru";
      case LANGUAGE_TYPE.FR_FR:
        return "fr";
      case LANGUAGE_TYPE.DE_DE:
        return "de";
      case LANGUAGE_TYPE.JA_JP:
        return "jp";
      default:
        return "en_US";
    }
  },

  getCurrentLanguage() {
    // 先读cookie，
    let locale = this.getCookie("locale");
    if (locale) {
      return locale;
    }
    // 再获取当前浏览器的语言
    let lang = (navigator.language || "").toLocaleLowerCase();

    switch (lang) {
      // 日语
      case "ja":
        return "ja_JP";

      // 简体中文
      case "zh-cn":
      case "zh":
        return "zh_CN";

      //繁体中文
      case "zh-tw":
        return "zh_TW";

      case "en": //英文
      case "en-us": //英文(美国)
        return "en_US";

      case "pt": //葡萄牙
      case "pt-br": //葡萄牙(巴西)
      case "pt-pt": //葡萄牙(葡萄牙)
        return "pt_BR";

      case "fr": // 法语
      case "fr-fr": // 法语(法国)
      case "fr-ca": // 法语(加拿大)
      case "fr-ch": // 法语(瑞士)
        return "fr_FR";

      case "de": // 德语
      case "de-at": // 德语（奥地利）
      case "de-de": // 德语（德国）
      case "de-li": // 德语（列支敦士登）
      case "de-ch": // 德语（瑞士）
        return "de_DE";

      case "ru": // 俄语
        return "ru_RU";
      case "it": // 意大利
        return "it_IT";

      case "th": // 泰语/th_TH
        return "th_TH";
      case "vi": // 越南语/vi_VN
        return "vi_VN";
      case "tr": // 土耳其语/tr_TR
        return "tr_TR";
      case "pl": // 波兰语/pl_PL
        return "pl_PL";
      case "nl": // 荷兰语/nl_NL
        return "nl_NL";
      default:
        return "en_US";
    }
  },
  //兼容老版的cookie写入
  setCookie(
    name: string,
    value: any,
    expiresDay: number,
    domain: string | undefined,
    noEncode?: any
  ) {
    //("locale",locale,100000,'.fotor.com')
    var cookieString = name + "= " + (value ? value : "");

    if (domain !== undefined) {
      cookieString = cookieString + "; domain=" + domain;
    }

    cookieString = cookieString + "; path=/";

    if (expiresDay > 0) {
      var date = new Date();
      date.setTime(date.getTime() + expiresDay * 24 * 3600 * 1000);
      cookieString = cookieString + "; expires=" + (date as any).toGMTString();
    }
    document.cookie = cookieString;
  },

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
      return parts.pop()!.split(";").shift();
    } else if (name === "locale") {
      return this.getBorwserlanguage();
    }
  },
  // 获取浏览器语言
  getBorwserlanguage(): LANGUAGE_TYPE {
    const lang = (navigator.language || "").toLocaleLowerCase();

    // 单独处理西班牙语
    if (/^es/.test(lang)) {
      return LANGUAGE_TYPE.EN_US;
    }
    switch (lang) {
      // 日语
      case "ja":
        return LANGUAGE_TYPE.JA_JP;
      // 简体中文
      case "zh-cn":
      case "zh":
        return LANGUAGE_TYPE.ZH_CN;
      //繁体中文
      case "zh-tw":
        return LANGUAGE_TYPE.ZH_TW;
      case "en": //英文
      case "en-us": //英文(美国)
        return LANGUAGE_TYPE.EN_US;
      case "es": // 西班牙语
        return LANGUAGE_TYPE.EN_US;
      case "pt": //葡萄牙
      case "pt-br": //葡萄牙(巴西)
      case "pt-pt": //葡萄牙(葡萄牙)
        return LANGUAGE_TYPE.PT_BR;
      case "fr": // 法语
      case "fr-fr": // 法语(法国)
      case "fr-ca": // 法语(加拿大)
      case "fr-ch": // 法语(瑞士)
        return LANGUAGE_TYPE.FR_FR;
      case "de": // 德语
      case "de-at": // 德语（奥地利）
      case "de-de": // 德语（德国）
      case "de-li": // 德语（列支敦士登）
      case "de-ch": // 德语（瑞士）
        return LANGUAGE_TYPE.DE_DE;
      case "ru": // 俄语
        return LANGUAGE_TYPE.RU_RU;
      default:
        // if (IS_CN) {
        //   return LANGUAGE_TYPE.ZH_CN
        // } else {
        return LANGUAGE_TYPE.EN_US;
      // }
    }
  },

  selectImageFile({
    accept = ".jpg,.jpeg,.png",
    multiple = true,
    useFocus = false
  }: uploadProps): Promise<Array<File>> {
    return new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = accept;
      input.multiple = multiple;
      input.style.display = "none";
      document.body.append(input);
      let isFocus = false;
      let timeout = -1;
      const onFocuse = () => {
        console.log("onFocuse");
        if (!isFocus) {
          timeout = setTimeout(() => {
            resolve([...(input.files || [])]);
            input.remove();
            window.removeEventListener("focus", onFocuse);
          }, 500) as unknown as number;
        }
      };

      input.onchange = (e) => {
        isFocus = true;
        console.log("e.target", e.target);
        if (e.target) {
          resolve((e.target as any).files);
        } else {
          resolve([]);
        }
        input.remove();
        window.removeEventListener("focus", onFocuse);
        if (timeout !== -1) {
          clearTimeout(timeout);
        }
        input.remove();
      };
      if (useFocus) {
        window.addEventListener("focus", onFocuse);
      }
      input.click();
    });
  },

  imageLoader(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = url;
      image.onload = function () {
        resolve(image);
      };
      image.onerror = function () {
        reject();
      };
    });
  },

  getImageFromFile(
    file: File,
    isAiGC = false
  ): Promise<{
    canvas: HTMLCanvasElement;
    acturalSize: Size;
    currentSize: Size;
  }> {
    return new Promise((resolve, reject) => {
      let _this = this;
      let image = document.createElement("img");
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = window.URL.createObjectURL(file);
      image.onload = function () {
        let { width, height } = image;
        let acturalSize = { width, height };
        // AIGC 最大边为1024
        const scale = _this.aspectFitScale(width, height, 1024);
        let newWidth = scale * width;
        let newHeight = scale * height;
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(image, 0, 0, width, height, 0, 0, newWidth, newHeight);
        setTimeout(() => {
          let currentSize = { width: newWidth, height: newHeight };
          window.URL.revokeObjectURL(image.src);
          resolve({ canvas, acturalSize, currentSize });
        }, 100);
      };
    });
  },

  getImageFromUrl(url: string): Promise<{ image: HTMLImageElement }> {
    return new Promise((resolve, reject) => {
      let image = document.createElement("img");
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = url;
      image.onload = function () {
        resolve({ image });
      };
    });
  },
  getOldCookie(name: string) {
    var strCookie = document.cookie;
    var arrCookie = strCookie.split("; ");
    for (var i = 0; i < arrCookie.length; i++) {
      var arr = arrCookie[i].split("=");
      if (arr[0] === name)
        try {
          return decodeURI(this.decodeUTF8(arr[1]));
        } catch (e) {
          return this.decodeUTF8(arr[1]);
        }
    }
    return "";
  },
  decodeUTF8(str: any) {
    return str.replace(
      /(\\u)(\w{4}|\w{2})/gi,
      function ($0: any, $1: any, $2: string) {
        return String.fromCharCode(parseInt($2, 16));
      }
    );
  },
  base64ToUint8Array(base64: string): Uint8Array {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  },
  /**
   * 最大边
   * @param width
   * @param height
   * @param maxSide
   * @returns
   */
  aspectFitScale(width: number, height: number, maxSide: number) {
    let scaleX: number = maxSide / width;
    let scaleY = maxSide / height;
    let scale = Math.min(scaleX, scaleY);
    return scale;
  },
  /**
   *
   * @param width 最小边
   * @param height
   * @param minSide
   * @returns
   */
  aspectFillScale(width: number, height: number, minSide: number) {
    let scaleX: number = minSide / width;
    let scaleY = minSide / height;
    let scale = Math.max(scaleX, scaleY);
    return scale;
  },
  checkFileType(file: File) {
    switch (file.type) {
      case "image/png":
      case "image/jpeg":
        return true;
      default:
        if (
          ["jpg", "png", "jpeg"].includes(
            file.name.slice(file.name.lastIndexOf(".")).toLocaleLowerCase()
          )
        ) {
          return true;
        }
        return false;
    }
  },
  async checkFileSize(file: File, maxSlider: number) {
    const url = window.URL.createObjectURL(file);
    const image = await this.imageLoader(url);
    const { width, height } = image;
    window.URL.revokeObjectURL(url);
    return width < maxSlider && height < maxSlider;
  },
  dataURItoBlob(dataURI: string): Blob {
    const [first, second] = dataURI.split(",");
    var mimeString = first.split(":")[1].split(";")[0]; // mime类型
    var byteString = atob(second); //base64 解码
    var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
    var intArray = new Uint8Array(arrayBuffer); //创建视图

    for (var i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: mimeString });
  },
  toFixed(num: number | string, fix: number = 0): string {
    if (typeof num === "number") {
      return num.toFixed(fix);
    }
    return num;
  },

  async checkImage(file: File) {
    if (!utils.checkFileType(file)) {
      return {
        result: false,
        message: "goart_image_upload_errror"
      };
    }
    if (file.size > SIZE_LIMIT) {
      return {
        result: false,
        message: "goart_image_file_size_error"
      };
    }
    const isSuccess = await utils.checkFileSize(file, MAX_WIDTH);
    if (!isSuccess) {
      return {
        result: false,
        message: "goart_image_size_error"
      };
    }
    return {
      result: true,
      message: ""
    };
  },
  unique<T>(arr: T[], key: string): T[] {
    const res = new Map();
    return arr.filter((a) => !res.has(a[key]) && res.set(a[key], 1));
  }
};

export default utils;
