/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-03 09:37:38
 * @Description:
 */
import "./EffectListCollapse.scss";

import { useCurrentEffect, useLanguage } from "src/hooks";

import EffectListCollapseItem from "../EffectListCollapseItem/EffectListCollapseItem";
import FTImageStore from "src/store/FTImageStore";
import type { IClassifyItem } from "src/store/types";
import { ArrowIcon, IconArrawDown } from "src/assets";
import React from "react";
import { actionGroupClickUp } from "src/analyze/actions";
import clsx from "clsx";
import styles from "./EffectListCollapse.module.scss";
import EventBusInstances, { EventName } from "src/constant/eventBus";
interface Iprops {
  effectList: any[];
  title: string;
  isCollapse: boolean;
  needCollapse?: boolean;
  id?: string;
  showNew?: boolean;
}
export default function EffectListCollapse(props: Iprops) {
  let {
    effectList,
    title,
    isCollapse,
    needCollapse = true,
    id = "",
    showNew = true
  } = props;
  // let newEffectList: any[] = [];
  let currentEffect = useCurrentEffect();
  const { t } = useLanguage();
  const handleClick = () => {
    FTImageStore.effectClassifyMap = FTImageStore.effectClassifyMap.map(
      (item: IClassifyItem) => {
        if (item.id === id) {
          const show = !item.collapse;
          item.collapse = show;
          actionGroupClickUp(item.id, item.enName, {
            action_type: show ? "show" : "hide"
          });
        }
        return item;
      }
    );
  };

  const [newEffectList, setNewEffectList] = React.useState<any[]>([]);
  const { language } = useLanguage();

  React.useEffect(() => {
    if (!effectList) return;
    let newEffectList: any[] = [];
    for (
      let i = 0;
      i < (effectList.length > 6 ? 6 : effectList.length);
      i += 3
    ) {
      newEffectList.push(effectList.slice(i, i + 3));
    }

    setNewEffectList(newEffectList);
  }, [effectList, language]);
  const currentEffectId = currentEffect
    ? currentEffect.id || currentEffect.prodId
    : "";
  return (
    <div className='effect_collapse' id={title}>
      {needCollapse ? (
        <div
          className={clsx(
            "effect_collapse_title",
            isCollapse && "effect_collapse_title_collapse"
          )}
          onClick={handleClick}>
          {title}
          <IconArrawDown />
        </div>
      ) : (
        <div className={styles["effect-collapse-fra"]}>
          <div className='effect_collapse_title1'>{title}</div>
          {effectList.length > 6 && (
            <div
              onClick={() => {
                EventBusInstances.emit(EventName.GO_SCROLL, id);
              }}
              className={styles["see-all"]}>
              {t("goart_see_all")} <ArrowIcon width={10} />
            </div>
          )}
        </div>
      )}
      <div
        className='effect_collapse_list'
        style={{
          display: !isCollapse ? "none" : "flex"
        }}>
        {newEffectList.map((item, index) => {
          return (
            <div key={id + "_" + index}>
              {item.map((ite) => {
                const id = ite.id || ite.prodId;
                return (
                  <EffectListCollapseItem
                    needCollapse={needCollapse}
                    key={id}
                    effect={ite}
                    active={currentEffectId === id}
                    showNew={showNew}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
