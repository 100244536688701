/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2021-12-07 18:24:19
 * @Description:
 */

export { default as deeplink } from "./deeplinkMobile";
export { default as imageProcesserSize } from "./imageProcesserSize";
export { default as urls } from "./url";
export * from "./errorCode";
export * from "./languageArray";
export enum defaultLength {
  MOBILE_WIDTH = 750
}

export const MAX_WIDTH = 8192;

export const SIZE_LIMIT = 40 * 1024 * 1024; // 40M

export enum EnumImageSizeType {
  HD = "HD",
  Preview = "Preview"
}

export const PROJECT_TYPE = "goart";

/**
 * 项目类型ID
 * 可以在功能清单表格中找到 或者@高昊获取
 */
export const PROJECT_TYPE_ID =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "c1c4d9514b5c4efe9943e6ac62cef9b4"
    : "c1c4d9514b5c4efe9943e6ac62cef9b4";

export const FREE_TRY_DIALOG_DATA = [
  {
    activity: "try",
    editor: {
      styleType: "free",
      title: "edit_free_trial_ad_title",
      titleFree: "edit_free_trial_ad_title_free",
      subtitle: "edit_free_trial_ad_titleInfo",
      descList: [
        {
          icon: "/public/icon/free-dailog/photo-gradient.svg",
          text: "edit_free_trial_ad_title1"
        },
        {
          icon: "/public/icon/free-dailog/material-gradient.svg",
          text: "edit_free_trial_ad_title2"
        },
        {
          icon: "/public/icon/free-dailog/upload-gradient.svg",
          text: "edit_free_trial_ad_title3"
        }
      ],
      tips: "edit_free_trial_ad_cancel_tip",
      upgradeBtn: "edit_free_trial_ad_ok",
      rightImg: "/img/Go art.jpeg"
    }
  },
  {
    activity: "discount",
    editor: {
      styleType: "free",
      title: "edit_discount_ad_title",
      titleIcon: "/public/img/free-dailog/fireworks.png",
      subtitle: "edit_discount_ad_titleInfo",
      descList: [
        {
          icon: "/public/icon/free-dailog/photo-gradient.svg",
          text: "edit_discount_ad_title1"
        },
        {
          icon: "/public/icon/free-dailog/material-gradient.svg",
          text: "edit_discount_ad_title2"
        },
        {
          icon: "/public/icon/free-dailog/upload-gradient.svg",
          text: "edit_discount_ad_title3"
        }
      ],
      upgradeBtn: "edit_discount_ad_ok",
      rightImg: "/img/Go art.jpeg"
    }
  }
];

export enum CheckStatus {
  pending = "pending",
  success = "success",
  fail = "fail",
  making = "making"
}

export enum EnumsShowStatus {
  origin,
  result,
  compare
}

export const toolTipProps = {
  background: "#fff",
  color: "#262B33",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "18px",
  fontFamily: "Poppins"
};

export const FAVOR = "FAVOR";
