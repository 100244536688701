/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-08 15:56:28
 * @Description:
 */
import "./EffectListCollapseItem.scss";

import {
  IconHot,
  IconNew,
  ReGenerateIcon,
  StarFillIcon,
  StarIcon
} from "src/assets";
import { imageLoadingClassName, utils } from "src/utils";

import { FTImageStore } from "src/store";
import { IEffectItem } from "src/store/types";
import { actionClickUp } from "src/analyze/actions";
import clsx from "clsx";
import styles from "./EffectListCollapseItem.module.scss";
import { Tooltip } from "antd";
import { useLanguage } from "src/hooks";
import { useEffect, useRef, useState } from "react";
import { autorun } from "mobx";
import { EnumsShowStatus } from "src/constant";
import EventBusInstances, { EventName } from "src/constant/eventBus";
import { goart_click_record } from "src/utils/untils";
interface Iprops {
  effect: IEffectItem;
  active: boolean;
  needCollapse: boolean;
  showNew?: boolean;
}

const TipsComponents = (props) => {
  const { children, title } = props;
  const ref = useRef(null);
  const container = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const position = useRef({
    top: 0,
    left: 0
  });
  return (
    <>
      {show && (
        <div
          onClick={(el) => {
            el.stopPropagation();
          }}
          onMouseLeave={() => {
            setShow(false);
          }}
          onMouseEnter={() => {
            setShow(true);
          }}
          style={{
            left: position.current.left,
            top: position.current.top
          }}
          className={styles["tips-position"]}
          ref={ref}>
          {title}
        </div>
      )}
      <div
        ref={container}
        className={styles["tips-content"]}
        onMouseLeave={() => {
          setShow(false);
        }}
        onMouseEnter={(el) => {
          try {
            const { x, y, width, height } =
              container.current!.getBoundingClientRect();
            position.current = {
              left: x + Math.floor(Number((width / 2).toFixed(2))),
              top: y + Math.floor(Number((height / 2).toFixed(2)))
            };
            // console.log(top, left);
            if (title) {
              setShow(true);
            }
          } catch {}
        }}>
        {children}
      </div>
    </>
  );
};

function Collection({ curCollectionApiStatus, effect, active, t }) {
  const [show, setShow] = useState(false);
  return (
    <Tooltip
      onVisibleChange={(visible) => {
        setShow(visible);
      }}
      // getPopupContainer={(el) => el}
      overlayClassName={styles["tooltips-white"]}
      title={
        <p
          onClick={(el) => {
            el.stopPropagation();
          }}>
          {t(effect.favorite ? "goart_collection_cancel" : "goart_collection")}
        </p>
      }>
      <div
        onClick={async (el) => {
          el.stopPropagation();
          if (curCollectionApiStatus.current) {
            return;
          }
          curCollectionApiStatus.current = true;
          await FTImageStore.handel_collection(
            [effect.id as string],
            !effect.favorite
          );
          curCollectionApiStatus.current = false;
        }}
        style={{
          display: show ? "flex" : ""
        }}
        className={clsx(
          styles["effect-collect"],
          active && styles["effect-active"]
        )}>
        {effect?.favorite ? (
          <StarFillIcon width={16} />
        ) : (
          <StarIcon width={16} />
        )}
      </div>
    </Tooltip>
  );
}

export default function EffectListCollapseItem(props: Iprops) {
  let { effect, active, needCollapse = true, showNew = true } = props;
  const { t } = useLanguage();
  const [result, setResult] = useState(FTImageStore.showStatus);

  useEffect(() => {
    let dispose = autorun(() => {
      setResult(FTImageStore.showStatus);
    });
    return () => {
      dispose();
    };
  }, []);

  function check_update_hot_new(effect) {
    try {
      let obj: any = localStorage.getItem("goart_choose_styles");
      let records: string | {} | null =
        localStorage.getItem(goart_click_record);
      if (records) {
        records = JSON.parse(records as string);
        //已经关闭了红点没必要判断了
        if (records![effect.classifyId]) {
          return;
        }
      } else {
        records = {};
      }
      if (obj) {
        obj = JSON.parse(obj);
        if (!obj[effect.classifyId]) {
          obj[effect.classifyId] = {};
        }
        obj[effect.classifyId][effect.id] = true;
      } else {
        obj = {
          [effect.classifyId]: {
            [effect.id]: true
          }
        };
      }
      localStorage.setItem("goart_choose_styles", JSON.stringify(obj));
      //开始判断是否全部点击过
      let findObj = obj[effect.classifyId];
      let findIndex = FTImageStore.effectClassifyMap.findIndex(
        (items) => items.id === effect.classifyId
      );
      if (findIndex > -1) {
        let arr = FTImageStore.effectClassifyMap[findIndex].list;
        for (let i = 0; i < arr.length; i++) {
          if (!findObj[arr[i].id] && (arr[i]?.isHot || arr[i]?.isNew)) {
            return;
          }
        }
        //全部都有
        records![effect.classifyId] = true;
        localStorage.setItem(goart_click_record, JSON.stringify(records));
        EventBusInstances.emit(EventName.UPDATE_LABEL);
      }
    } catch {}
  }

  const handleChooseEffect = (el, hasRegenerate = false) => {
    check_update_hot_new(effect);
    FTImageStore.getGoartEffect(effect, "goart_effect", hasRegenerate);
    actionClickUp(utils.toFixed(effect.id as number, 0), effect.enName, {
      boolean1:
        FTImageStore.searchText !== "" &&
        FTImageStore.selectEffectTags.length === 0,
      boolean2: FTImageStore.selectEffectTags.length !== 0,
      button_position: hasRegenerate ? "goart_regenerate" : "goart_effect",
      category_id: effect.classifyId,
      category_name: effect.firstCategoryEn,
      style_id: effect?._id
    });
  };

  const curCollectionApiStatus = useRef(false);
  return (
    <div
      data-name={effect.name}
      data-id={effect.prodId || effect.id}
      id={`fotor_${effect.id}`}
      className={clsx(
        "effect_collapse_item",
        active && "active",
        styles["effect-fra"],
        needCollapse && effect.name.replace(/ /g, () => "_")
      )}
      onClick={handleChooseEffect}>
      <div
        className={clsx("effect_collapse_item_image", imageLoadingClassName())}>
        {/* <Tooltip overlayClassName={styles["tooltips"]} title={"dqwdqwdqw"}> */}

        <TipsComponents title={effect.tipsContent}>
          <div className={styles["effect-image-fra"]}>
            {effect?.beforeUrl && (
              <div className={styles["effect-after"]}>
                <div>
                  <img
                    src={effect.beforeUrl}
                    alt={effect.name}
                    className='loading-skeleton-back2'
                  />
                </div>
              </div>
            )}
            <img
              src={effect.url}
              alt={effect.name}
              className='loading-skeleton-back2'
            />
            <div className={clsx("effect_collapse_item_check")}></div>

            {(effect.isNew || effect.isHot) && (
              <div className='effect_collapse_item_new'>
                {/* {effect.isHot && <IconHot />} */}
                {/* {effect.isNew && showNew && <IconNew />} */}
                {effect.isNew ? (
                  <span className={styles["items-new"]}>NEW</span>
                ) : (
                  <span className={styles["items-hot"]}>HOT</span>
                )}
              </div>
            )}
          </div>
        </TipsComponents>
        {/* </Tooltip> */}
        {active && result !== EnumsShowStatus.origin && effect?.aigc && (
          <Tooltip
            overlayClassName={styles["tooltips-white"]}
            title={t("goart_regenerate")}>
            <div
              onClick={(el) => {
                el.stopPropagation();
                // delete FTImageStore.usedEffect[effect.id];
                handleChooseEffect(el, true);
              }}
              className={clsx(
                styles["regenerate"],
                active && styles["regenerate-active"]
              )}>
              <ReGenerateIcon width={14} />
            </div>
          </Tooltip>
        )}
        <Collection
          t={t}
          active={active}
          effect={effect}
          curCollectionApiStatus={curCollectionApiStatus}
        />
        {/* <Tooltip
          // getPopupContainer={(el) => el}
          overlayClassName={styles["tooltips-white"]}
          title={
            <p
              onClick={(el) => {
                el.stopPropagation();
              }}>
              {t(
                effect.favorite ? "goart_collection_cancel" : "goart_collection"
              )}
            </p>
          }>
          <div
            onClick={async (el) => {
              el.stopPropagation();
              if (curCollectionApiStatus.current) {
                return;
              }
              curCollectionApiStatus.current = true;
              await FTImageStore.handel_collection(
                [effect.id as string],
                !effect.favorite
              );
              curCollectionApiStatus.current = false;
            }}
            className={clsx(
              styles["effect-collect"],
              active && styles["effect-active"]
            )}>
            {effect?.favorite ? (
              <StarFillIcon width={16} />
            ) : (
              <StarIcon width={16} />
            )}
          </div>
        </Tooltip> */}
      </div>
      <span className='effect_collapse_item_title'>{effect.name}</span>
    </div>
  );
}
