import { IconMagicWand, IconMagicWand2, IconRefresh } from "src/assets";
import React, { useEffect } from "react";
import { sleep, withUnit } from "src/utils";

import { ExampleEffectItem } from "src/store/types";
import FTCompareV3 from "../FTCompareV3";
import clsx from "clsx";
import style from "./FTSampleList.module.scss";
import { useLanguage } from "src/hooks";
import { useRandomList } from "./useRandomList";

interface IFTSampleListProps {
  list: ExampleEffectItem[];
  onEffect?: (item: ExampleEffectItem) => void;
}

export default function FTSampleList(props: IFTSampleListProps) {
  const { list } = props;
  const [pageSize, setPageSize] = React.useState<number>(
    window.innerWidth <= 960 ? 2 : 3
  );
  const { showList, prevPage, nextPage } = useRandomList(list, pageSize);
  const { t } = useLanguage();
  const [currentAnimation, setCurrentAnimation] = React.useState<number>(0);
  const nextTimerRef = React.useRef<number>(-1);

  const handleNextPage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    nextPage();
    if (nextTimerRef.current !== -1) {
      clearTimeout(nextTimerRef.current);
      nextTimerRef.current = -1;
    }
    setCurrentAnimation(0);
  };
  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      if (width > 960) {
        setPageSize(3);
        setCurrentAnimation(0);
      } else if (width <= 960) {
        setPageSize(2);
        setCurrentAnimation(0);
      }
    };
    window.addEventListener("resize", onResize);
    return () => {
      if (nextTimerRef.current !== -1) {
        clearTimeout(nextTimerRef.current);
        nextTimerRef.current = -1;
      }
      window.removeEventListener("resize", onResize);
    };
  });
  const loadingList = window.innerWidth <= 960 ? [1, 2] : [1, 2, 3];
  return (
    <div className={clsx(style.sample_list_wrapper)}>
      <div className={style.sample_list_header}>
        <div className={style.sample_list_header_title}>
          {t("goart_banner_title")}
        </div>
        {/* <div
          className={style.sample_list_header_pagination}
          onClick={handleNextPage}>
          <IconRefresh />
          <div>{t("noimage_sample_change")}</div>
        </div> */}
      </div>
      <div className={style.sample_list_content}>
        {showList.length === 0 &&
          loadingList.map((item, index) => (
            <div
              className='loading-skeleton-back2 loading-skeleton-sample'
              key={index}
              style={{
                aspectRatio: "290 / 217",
                borderRadius: withUnit(6)
              }}></div>
          ))}
        {showList.map((item, index) => (
          <div
            onClick={(el) => {
              el.stopPropagation();
              props.onEffect?.(item);
            }}
            key={index + item.templateId}
            className={style.sample_list_item}>
            <div className={style.sample_list_item_img}>
              <FTCompareV3
                dataKey={index + ""}
                key={item.templateId}
                originalUrl={item.effectsImg}
                cutoutUrl={item.originImg}
                originalAlt={""}
                cutoutAlt={""}
                animation={currentAnimation === index}
                onAnimation={() => {
                  // console.log('onAnimation', index);
                  if (nextTimerRef.current !== -1) {
                    clearTimeout(nextTimerRef.current);
                    nextTimerRef.current = -1;
                  }
                  setCurrentAnimation(index);
                }}
                onFinish={() => {
                  // console.log('onFinish', index);
                  if (currentAnimation === index) {
                    if (nextTimerRef.current !== -1) {
                      clearTimeout(nextTimerRef.current);
                      nextTimerRef.current = -1;
                    }
                    sleep(200, (timer) => (nextTimerRef.current = timer)).then(
                      () => {
                        if (currentAnimation === index) {
                          setCurrentAnimation((currentAnimation + 1) % 3);
                        }
                      }
                    );
                  }
                }}
                onNext={() => {
                  // console.log('onNext', index);
                  if (nextTimerRef.current !== -1) {
                    clearTimeout(nextTimerRef.current);
                    nextTimerRef.current = -1;
                  }
                  sleep(200, (timer) => (nextTimerRef.current = timer)).then(
                    () => {
                      if (currentAnimation === index) {
                        setCurrentAnimation((index + 1) % 3);
                      }
                    }
                  );
                }}
              />
              <div className={style.sample_list_item_desc}>{item.name}</div>
            </div>

            <span
              className={style.sample_list_item_try}
              onClick={(e) => {
                // e.stopPropagation();
                // e.preventDefault();
              }}>
              <span className={style.sample_list_item_try_icon}>
                <IconMagicWand2 />
              </span>
              <span className={style.sample_list_item_try_text}>
                {t("noimage_sample_try")}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
