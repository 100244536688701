import { downloadFile, imageLoader, rangNumber, utils } from "src/utils";

import { FTImageStore } from "./store";
/*
 * @Author: Jackson
 * @Email: zhangjiajun@everimaging.com
 * @Date: 2021-12-01 14:06:14
 * @Description: Process images with Art Effect
 *
 */
// import { SageMakerRuntimeClient, InvokeEndpointCommand } from "@aws-sdk/client-sagemaker-runtime";
import axios from "axios";
import urls from "./constant/url";

class ImageProcessor {
  public imageUrl = "";
  /**
   * 最高超分辨率
   */
  private SUPPER_RESOLUTION_HIGH_ID = "2880";
  /**
   * 中等超分辨率
   */
  private SUPPER_RESOLUTION_MEDIUM_ID = "1440";

  /**
   * 算法固定支持图片的大小，宽高一致（直接拉伸）
   */
  private IMAGE_SIDE_VALUE = 720;

  private EFFECT_FORMAT_STRING = "tfs-model-name=model, tfs-model-version=";

  private static COMMON_PARAMS = {
    EndpointName: "tfs-endpoint",
    Accept: "image/jpeg",
    ContentType: "application/x-image"
  };

  /**
   * SagaMakerRuntime for call the saga service
   */
  // private sageMakerClient: SageMakerRuntimeClient;

  constructor() {
    // this.sageMakerClient = new SageMakerRuntimeClient({
    //     region: 'us-west-2',
    //     credentials: {
    //         accessKeyId: 'AKIAXW4AZNAZH22OL5F2',
    //         secretAccessKey: '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'
    //     },
    //     apiVersion: '2017-05-13'
    // });
  }

  /**
   * 预处理，将非720x720的图片转换尺寸和格式->JPEG(三通道)图片
   * @return 返回promise<Uint8Array>
   */
  preProcessImage(imageElement: HTMLImageElement | HTMLCanvasElement): any {
    console.log(
      "#resize image to 720, source width",
      imageElement.width,
      ", height:",
      imageElement.height
    );
    //convert image to 720x720
    let canvas = document.createElement("canvas");
    canvas.width = canvas.height = this.IMAGE_SIDE_VALUE;
    let context = canvas.getContext("2d")!;
    context.drawImage(
      imageElement,
      0,
      0,
      imageElement.width,
      imageElement.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    // return new Promise((resolve)=>{
    //     canvas.toBlob(function(blob: any) {
    //         //TODO: exception handle
    //         // blob.arrayBuffer().then((buffer: ArrayBuffer)=>{
    //         //     resolve(new Uint8Array(buffer));
    //         // });
    //         if(blob.arrayBuffer) {
    //           blob.arrayBuffer().then((buffer: ArrayBuffer)=>{
    //               resolve(new Uint8Array(buffer));
    //           });
    //         } else {
    //           let reader = new FileReader();
    //           reader.onload = function(result) {
    //             resolve(new Uint8Array(result.target!.result as ArrayBuffer));
    //           }
    //           reader.readAsArrayBuffer(blob);
    //         }
    //     }, "image/jpeg", 1.0);
    // });

    // 新的需求是返回base64 string
    return canvas.toDataURL("image/jpeg", 1.0);
  }

  /**
   * 生成特效脚本
   */
  generateAttribute(effectID: string): string {
    return this.EFFECT_FORMAT_STRING + effectID;
  }

  /**
   * Process the input image
   */
  async process(
    imageFile: HTMLImageElement | HTMLCanvasElement,
    effectID?: string
  ): Promise<HTMLImageElement> {
    return new Promise(async (resolve, reject) => {
      try {
        // let bytes: Uint8Array;
        let sourceWidth = imageFile.width;
        let sourceHeight = imageFile.height;
        const scale = utils.aspectFitScale(
          sourceWidth,
          sourceHeight,
          this.IMAGE_SIDE_VALUE
        );
        let targetWidth = scale * sourceWidth;

        let targetHeight = scale * sourceHeight;

        console.log("#begin process file:", imageFile);
        // let bytes = await this.preProcessImage(imageFile);
        // let effectStr = this.generateAttribute(effectID || "202");
        // console.log('#load image data:', bytes);
        // let params = {...ImageProcessor.COMMON_PARAMS, Body: bytes, CustomAttributes: effectStr};
        // console.log('#params:', params);
        // const command = new InvokeEndpointCommand(params);
        // this.sageMakerClient.send(command)
        const base64Str = this.preProcessImage(imageFile);
        this.apiProcessByBase64(base64Str, effectID || "202")
          .then((result: any) => {
            console.log("#result:", result);
            // // 流式文件 转成 img
            // let resultStr = window.URL.createObjectURL(new Blob([result.Body.buffer], { type: 'image/jpeg' }));
            // 新的接口直接是base64 string
            const resultStr = result.data;
            let img = document.createElement("img");
            img.width = targetWidth;
            img.height = targetHeight;
            img.setAttribute("crossOrigin", "Anonymous");
            img.src = resultStr;
            //TODO: test code
            img.onload = function () {
              resolve(img);
            };
            // document.body.append(img);
            // console.log('#result image:', resultStr);
          })
          .catch((error: any) => {
            reject();
            //TODO: handle exception
            console.log("#error:", error);
          })
          .finally(() => {
            console.log("#finally");
          });
      } catch (error) {
        console.log("#error:", error);
        reject();
      }
    });
  }

  async processByUrl(url: string, effectID?: any) {
    return this.apiProcessByUrl(url, effectID || "202")
      .then((result) => {
        FTImageStore.progress.task = 100;
        FTImageStore.progress.download = 10;
        FTImageStore.artDownloadImageUrl = result.data.url;
        return downloadFile(result.data.url, (progress) => {
          console.log(progress);
          FTImageStore.progress.download = progress * 0.85;
        }).then((blob) => {
          const url = window.URL.createObjectURL(blob);
          FTImageStore.progress.download = 100;
          return imageLoader(url);
        });
      })
      .catch((error) => {
        console.log("#error:", error);
        return Promise.reject(error);
      });
  }

  async PreProcessGray(
    imageFile: HTMLImageElement | HTMLCanvasElement
  ): Promise<HTMLImageElement | HTMLCanvasElement> {
    // TODO: 针对需要进行黑白处理得图片进行处理
    return new Promise((resolve, reject) => {
      resolve(imageFile);
    });
  }

  apiProcessByBase64(imgBase64: string, effectID: any) {
    const base64 = imgBase64.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    const url = `${urls.baseUrl}tfs-sagemaker-runtime`;
    return axios.post(
      url,
      {
        base64,
        "tfs-model-version": String(effectID)
      },
      {
        withCredentials: true
      }
    );
  }
  apiProcessByUrl(imageUrl: string, effectID: any) {
    const url = `${urls.baseUrl}tfs-sagemaker-runtime`;
    return axios.post(
      url,
      {
        url: imageUrl,
        "tfs-model-version": String(effectID),
        respUrl: true
      },
      {
        withCredentials: true
      }
    );
  }
}

export default new ImageProcessor();
