export const goart_click_record = "goart_click_record";
/**存放点击的红点 */
export function cache_click_red_tag(item) {
  try {
    let obj: {} | string | null = localStorage.getItem(goart_click_record);
    if (obj) {
      obj = JSON.parse(obj as string);
      obj![item.id] = true;
      localStorage.setItem(goart_click_record, JSON.stringify(obj));
    } else {
      localStorage.setItem(
        goart_click_record,
        JSON.stringify({
          [item.id]: true
        })
      );
    }
  } catch {}
}
/**判断是否需要展示红点 */
export function checkHasCLickRedTag(id: string) {
  try {
    let obj: { id: string } | string | null =
      localStorage.getItem(goart_click_record);
    if (obj) {
      obj = JSON.parse(obj);
      if ((obj as { id: string })[id]) {
        return false;
      }
    }
    return true;
  } catch {
    return true;
  }
}
