import type {
  FTCanvasStoreType,
  FTImageStoreType,
  FTUserStoreType,
  IClassifyItem
} from "src/store/types";
import FTEventTarget, { FTEVENT_CONSTANTS } from "src/utils/FTEventTarget";
import { FTLang, LANGUAGE_TYPE, utils } from "src/utils";
/*
 * @Author: Li Xingxing
 * @Email: lixingxing@everimaging.com
 * @Date: 2023-02-02 16:24:31
 * @Description:
 */
import { useEffect, useLayoutEffect, useState } from "react";

import FTCanvasStore from "src/store/FTCanvasStore";
import FTImageStore from "src/store/FTImageStore";
import { FTUserStore } from "src/store";
import { LANUGAGE_ARRAY } from "src/constant";
import React from "react";
import { autorun } from "mobx";
import { message } from "antd";

// 获取当前展开功能类型
export function useEffectList() {
  let [value, setValue] = useState(FTImageStore.effectList);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.effectList);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前展开功能类型
export function useChoicenessList() {
  let [value, setValue] = useState(FTImageStore.choicenessList);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.choicenessList);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前展开功能类型
export function useEffectClassifyMap(): IClassifyItem[] {
  let [value, setValue] = useState(FTImageStore.effectClassifyMap);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.effectClassifyMap);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前展开功能类型
export function useCurrentEffectType() {
  let [value, setValue] = useState(FTImageStore.currentEffectType);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.currentEffectType);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

export function useCurrentEffectTypeId() {
  let [value, setValue] = useState(FTImageStore.currentClassifyId);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.currentClassifyId);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前展开功能类型
export function useEffectTags() {
  let [value, setValue] = useState(FTImageStore.effectTags);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.effectTags);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前选中的分类
export function useEffectSelectTags() {
  let [value, setValue] = useState(FTImageStore.selectEffectTags);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.selectEffectTags);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取当前展开功能类型
export function useCurrentEffect() {
  let [value, setValue] = useState(FTImageStore.currentEffect);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.currentEffect);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取搜索内容
export function useSearchText(): [string, any] {
  let [value, setValue] = useState(FTImageStore.searchText);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.searchText);
    });
    return () => {
      disposer && disposer();
    };
  }, []);

  const updateSearchText = (text: string) => {
    FTImageStore.searchText = text;
    FTImageStore.currentClassify = "";
    FTImageStore.currentClassifyId = "";
    FTImageStore.search();
  };
  return [value, updateSearchText];
}

// 获取当前上传图片
export function useUploadImage() {
  let [value, setValue] = useState(FTImageStore.currentImage);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.currentImage);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

// 获取搜索内容
export function useSearchList() {
  let [value, setValue] = useState(FTImageStore.searchList);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.searchList);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

export function useSearchLoading() {
  let [value, setValue] = useState(FTImageStore.searchLoading);
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(FTImageStore.searchLoading);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(utils.checkIsMobile());

  const listener = function (mql: any) {
    setIsMobile(mql.matches);
  };

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia("(max-width: 750px)"); // 注意：一定要使用括号包裹，否则无法实时监听
    setIsMobile(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", listener);
    message.config({
      top: mediaQueryList.matches ? 30 : 60
    });
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, []);

  return isMobile;
};

/**
 * 根据store获取数据
 * @fn fn
 * @returns
 */
export const useImageStoreFN = <T>(fn: (state: FTImageStoreType) => T) => {
  const [value, setValue] = useState(fn(FTImageStore));
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(fn(FTImageStore));
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
};

/**
 * 根据store获取数据
 * @fn fn
 * @returns
 */
export const useCanvasStoreFN = <T>(fn: (state: FTCanvasStoreType) => T) => {
  const [value, setValue] = useState(fn(FTCanvasStore));
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(fn(FTCanvasStore));
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
};

export const useUserStoreFN = <T>(fn: (state: FTUserStoreType) => T) => {
  const [value, setValue] = useState(fn(FTUserStore));
  useEffect(() => {
    let disposer = autorun(() => {
      setValue(fn(FTUserStore));
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  return value;
};

export const useLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState(FTUserStore.language);
  useEffect(() => {
    const disposer = autorun(() => {
      setCurrentLanguage(FTUserStore.language);
    });
    return () => {
      disposer && disposer();
    };
  }, []);
  const changeLanguage = async (language: string) => {
    await FTLang.updateLanguage(language as LANGUAGE_TYPE);
    FTUserStore.setLanguage(language);
    FTImageStore.getEffectList(language);
    // 修改SEO信息
    document.title = FTLang._("goart - seo - title");
    const desc = document.querySelector('meta[name="description"]');
    if (desc) {
      desc.setAttribute("content", FTLang._("goart - seo - description"));
    }

    setTimeout(() => {
      setCurrentLanguage(language as LANGUAGE_TYPE);
      window.mobileCommon && window.mobileCommon.refreshLanguage(language);
    });
  };
  const languageKey = React.useMemo(() => {
    return (
      LANUGAGE_ARRAY.find((item) => item.key === currentLanguage)
        ?.languageKey ?? "en"
    );
  }, [currentLanguage]);

  const languageNum = React.useMemo(() => {
    return (
      LANUGAGE_ARRAY.find((item) => item.key === currentLanguage)
        ?.languageNum ?? 2
    );
  }, [currentLanguage]);
  return {
    language: currentLanguage,
    _: (key: string, values?: any) => FTLang._(key, values),
    t: (key: string, values?: any) => FTLang._(key, values),
    changeLanguage,
    languageKey,
    languageNum
  };
};

/**
 * 路由参数链接
 */
export const useQueryRoute = () => {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const classify = query.get("classify");
    const effect = query.get("effect");
    const classifyId = query.get("classifyid");
    const imageUrl = query.get("url");
    const bgUrl = query.get("bg_url");
    const isAutoRun = query.get("autorun");
    if (classify) {
      FTImageStore.currentClassify = classify;
    }
    if (effect) {
      FTImageStore.initEffectId = effect;
    }
    if (classifyId) {
      FTImageStore.initClassifyId = classifyId;
    }

    if (imageUrl) {
      FTImageStore.initImageUrl = imageUrl;
    }
    if (bgUrl) {
      FTImageStore.initImageUrl = bgUrl;
    }
    if (isAutoRun === "true") {
      FTImageStore.initAutoRun = true;
    }
    const isDev = process.env.NODE_ENV === "development";
    if (!isDev) {
      if (classify || effect || classifyId || imageUrl || bgUrl) {
        window.history.replaceState(null, "", window.location.pathname);
      }
    }
  }, []);
};
