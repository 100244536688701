class EventBus {
  listObj = {};
  constructor() {}

  emit(name: EventName, stats?) {
    if (this.listObj[name]) {
      this.listObj[name].forEach((items) => {
        items(stats);
      });
    }
  }

  addEvents(name: EventName, fn) {
    if (this.listObj[name]) {
      this.listObj[name].push(fn);
    } else {
      this.listObj[name] = [fn];
    }
  }

  removeEvents(name, fn) {
    if (this.listObj[name]) {
      for (let i = 0; i < this.listObj[name].length; i++) {
        if (this.listObj[name][i] === fn) {
          this.listObj[name].splice(i, 1);
          break;
        }
      }
    }
  }
}
const EventBusInstances = new EventBus();
export enum EventName {
  GO_SCROLL = "GO_SCROLL",
  UPDATE_LABEL = "UPDATE_LABEL",
  CLEAR_TIPS = "CLEAR_TIPS"
}

try {
  window["EventBusInstances"] = EventBusInstances;
} catch {}

export default EventBusInstances;
