import { DimondIcon, IconClose, RoyalIcon, ShareIcon } from "src/assets";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { autorun } from "mobx";
import { FTCanvasStore, FTImageStore, FTUserStore } from "src/store";
import Bg from "src/assets/img/vipBg.jpg";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { useIsMobile, useLanguage } from "src/hooks";
import dayjs from "dayjs";
import { EnumsShowStatus } from "src/constant";
import FtShare from "@ft/share";
import { FTAddUseNums } from "src/server/api";
import JSon from "./data.json";
import { actionClickLimitPopAnlazy } from "src/analyze/actions";
let timer;
const LimitComp = ({ vipStatus }) => {
  const { t } = useLanguage();

  useEffect(() => {
    if (FTImageStore.showNoCreditsPop.time) {
      clearTimeout(timer);
      timer = setTimeout(
        () => {
          FTImageStore.showNoCreditsPop.visible = false;

          FTImageStore.getGoartEffect(
            FTImageStore.showNoCreditsPop.effect,
            "retry_button",
            FTImageStore.showNoCreditsPop.hasGenerate
          );
        },
        1000 * 60 * FTImageStore.showNoCreditsPop.time
      );
    }
  }, []);

  const [des, setDes] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setDes(des + 1 > 2 ? 0 : des + 1);
    }, 10000);
  }, [des]);

  return (
    <div className={styles["container"]}>
      <div className={styles["limit-inner"]}>
        <div className={styles["limit-loading"]}>
          <Player autoplay loop src={JSon} style={{ width: "120px" }}>
            <Controls visible={false} />
          </Player>
        </div>
        <p className={styles["title"]}>{t("goart_line_title")}</p>
        <p className={styles["des"]}>
          {des === 0 && t("goart_line_des1")}
          {des === 1 && t("goart_line_des2")}
          {des === 2 && t("goart_line_des3")}
        </p>
        {vipStatus === "no" && (
          <div
            onClick={() => {
              actionClickLimitPopAnlazy();
              FTUserStore.showPaymnet({
                vipType: "vip",
                position: "goart_upgrade_vip",
                otherParam: {
                  scenesId: "012"
                },
                callback: () => {
                  clearTimeout(timer);
                  FTImageStore.showNoCreditsPop.visible = false;
                  FTImageStore.getGoartEffect(
                    FTImageStore.showNoCreditsPop.effect,
                    "retry_button",
                    FTImageStore.showNoCreditsPop.hasGenerate
                  );
                }
              });
            }}
            style={{
              justifyContent: "center",
              width: "100%"
            }}
            className={clsx(styles["up-btn"])}>
            <DimondIcon width={16} />
            {t(
              FTImageStore.showNoCreditsPop.code === "429"
                ? "goart_stack_tips_btn"
                : "goart_up_gray_limit_btn"
            )}
          </div>
        )}
        {vipStatus === "vip" && (
          <div
            onClick={() => {
              FTUserStore.showPaymnet({
                vipType: "vip",
                position: "goart_upgrade_vip",
                otherParam: {
                  scenesId: "012"
                },
                callback: () => {
                  clearTimeout(timer);
                  FTImageStore.showNoCreditsPop.visible = false;
                  FTImageStore.getGoartEffect(
                    FTImageStore.showNoCreditsPop.effect,
                    "retry_button",
                    FTImageStore.showNoCreditsPop.hasGenerate
                  );
                }
              });
            }}
            style={{
              width: "100%"
            }}
            className={clsx(
              styles["up-btn"],
              vipStatus === "vip" && styles["up-btn-vip"]
            )}>
            <RoyalIcon width={16} />
            {t("goart_stack_tips_btn")}
          </div>
        )}
      </div>
    </div>
  );
};

const NoCreditsPop = () => {
  const [show, setShow] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [vipStatus, setVipStatus] = useState(
    FTUserStore.isVip ? (FTUserStore.isSvip ? "svip" : "vip") : "no"
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    // reaction(
    //   () => FTImageStore.showNoCreditsPop.visible,
    //   () => {
    //     setShowPop(FTImageStore.showNoCreditsPop.visible);
    //   }
    // );

    autorun(() => {
      setVipStatus(
        FTUserStore.isVip ? (FTUserStore.isSvip ? "svip" : "vip") : "no"
      );
      setShowPop(FTImageStore.showNoCreditsPop.visible);
    });
  }, []);

  function handel_records() {
    setShow(!show);
  }

  function handel_reset_pre() {
    FTImageStore.currentEffect = FTImageStore.prevEffect;
    if (
      FTImageStore.currentEffect &&
      FTImageStore.usedEffect[FTImageStore.currentEffect.id]
    ) {
      FTImageStore.effectElement =
        FTImageStore.usedEffect[FTImageStore.currentEffect.id];
      FTImageStore.resultSize = {
        width: FTImageStore.effectElement.naturalWidth,
        height: FTImageStore.effectElement.naturalHeight
      };
      FTImageStore.currentIntensity = FTImageStore.currentEffect.blend * 100;
      FTCanvasStore.draw();
      FTImageStore.showStatus = EnumsShowStatus.result;
    } else {
      FTImageStore.showStatus = EnumsShowStatus.origin;
    }
  }

  const [shareShown, setShareShown] = useState(false);

  const { t } = useLanguage();
  if (!showPop) {
    return null;
  }
  if (FTImageStore.showNoCreditsPop.code === "429") {
    return <LimitComp vipStatus={vipStatus} />;
  }
  return (
    <>
      <FtShare
        onClickMask={() => {
          setShareShown(false);
        }}
        isMobile={isMobile}
        shareLink={`https://goart.fotor.com`}
        theme='light'
        onClose={() => {
          setShareShown(false);
        }}
        shareDesc={
          "Amazing AI editing and generating tool by Fotor @fotor_com. Sign up to get 10 free credits for AI image creating. "
        }
        desc={t("goart_share_des")}
        title={t("goart_share_title")}
        env={"production"}
        open={shareShown}
        coverSrc={`${process.env.PUBLIC_URL}/img/background/Twitter.jpg`}
        shareTo={["discord", "facebook", "twitter", "pinterest", "linkedin"]}
        zIndex={30000}
        onShared={() => {}}
      />
      <div
        onClick={() => {
          if (isMobile) {
            handel_reset_pre();
            FTImageStore.showNoCreditsPop.visible = false;
            setShow(false);
          }
        }}
        className={styles["container"]}>
        <div
          onClick={(el) => {
            el.stopPropagation();
          }}
          className={styles["inner"]}>
          <div
            className={clsx(
              styles["inner-bg"],
              isMobile && styles["inner-bg-mobile"]
            )}>
            <img alt='' src={Bg} />
          </div>
          <div
            onClick={() => {
              handel_reset_pre();
              FTImageStore.showNoCreditsPop.visible = false;
              setShow(false);
            }}
            className={styles["close"]}>
            <IconClose width={12} />
          </div>
          <div className={styles["inner-content"]}>
            <p className={styles["title"]}>
              {FTImageStore.showNoCreditsPop.code === "429"
                ? t("goart_flow_title")
                : t("goart_limit_title")}
            </p>
            <p className={styles["des"]}>
              {FTImageStore.showNoCreditsPop.code === "429"
                ? t("goart_flow_limit")
                : vipStatus === "vip"
                  ? t("goart_vip_limit_title", {
                      number: 1
                    })
                  : t("goart_free_limit_title")}
            </p>

            {FTImageStore.showNoCreditsPop.code !== "429" && (
              <div className={styles["tips"]}>
                {t("goart_limit_pop_tips", {
                  timer_key:
                    dayjs().add(1, "day").format("YYYY/MM/DD") + " 00:00:00"
                })}
              </div>
            )}

            {vipStatus !== "svip" && (
              <div
                onClick={() => {
                  FTUserStore.showPaymnet({
                    vipType: "vip",
                    position: "goart_upgrade_vip",
                    otherParam: {
                      scenesId: "012"
                    },
                    callback: () => {
                      localStorage.setItem(
                        "fotor_goart_record_no_credits_pop",
                        `${show}`
                      );
                      FTImageStore.showNoCreditsPop.visible = false;
                      setShow(false);
                      FTImageStore.getGoartEffect(
                        FTImageStore.showNoCreditsPop.effect,
                        "retry_button",
                        FTImageStore.showNoCreditsPop.hasGenerate
                      );
                    }
                  });
                }}
                className={clsx(
                  styles["up-btn"],
                  vipStatus === "vip" && styles["up-btn-vip"],
                  FTImageStore.showNoCreditsPop.code === "429" &&
                    styles["up-btn-vip-limit"]
                )}>
                {vipStatus === "vip" ? (
                  <RoyalIcon width={16} />
                ) : (
                  <DimondIcon width={16} />
                )}
                {t(
                  FTImageStore.showNoCreditsPop.code === "429"
                    ? "goart_stack_tips_btn"
                    : "goart_up_gray_limit_btn"
                )}
              </div>
            )}

            {vipStatus === "svip" && (
              <div
                onClick={() => {
                  FTUserStore.showPaymnet({
                    vipType: "vip",
                    position: "goart_upgrade_vip",
                    otherParam: {
                      scenesId: "012"
                    },
                    callback: () => {
                      localStorage.setItem(
                        "fotor_goart_record_no_credits_pop",
                        `${show}`
                      );
                      FTImageStore.showNoCreditsPop.visible = false;
                      setShow(false);
                      FTImageStore.getGoartEffect(
                        FTImageStore.showNoCreditsPop.effect,
                        "retry_button",
                        FTImageStore.showNoCreditsPop.hasGenerate
                      );
                    }
                  });
                }}
                className={clsx(styles["up-btn"], styles["up-btn-vip"])}>
                {<RoyalIcon width={16} />}
                {t("goart_stack_tips_btn")}
              </div>
            )}
            {/* 
            {vipStatus === "vip" && (
              <div
                className={clsx(
                  styles["checkbox"],
                  show && styles["checkbot-active"]
                )}>
                <div onClick={handel_records}>
                  <YesIcon width={12} />
                </div>
                {t("goart_checkbox_confirm")}
              </div>
            )} */}

            {vipStatus === "no" && (
              <>
                <div className={styles["upgrap-gap"]}>
                  <span></span>
                  {t("goart_or")}
                  <span></span>
                </div>

                <p className={styles["upgrap-des"]}>{t("goart_share_des")}</p>

                <div
                  onClick={() => {
                    setShareShown(true);
                    FTAddUseNums();
                  }}
                  className={styles["share-btn"]}>
                  <ShareIcon width={16} />
                  {t("goart_share_btn")}
                </div>
              </>
            )}

            {vipStatus === "no" && (
              <div className={styles["upgray-des"]}>
                {t("goart_share_community_nums")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoCreditsPop;
